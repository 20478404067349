<template>
  <a-spin :spinning="spinning">
    <template #indicator>
      <Loading></Loading>
    </template>
    <div class="pc" v-if="orderDetails">
      <div class="form-info">
        <div class="title-lang">
          <div class="title">{{ $t('message.checkout.title') }}</div>
          <Lang></Lang>
        </div>
        <div class="description">{{ $t('message.checkout.description') }}</div>

        <div class="methods">
          <div class="method-title">
            {{ $t('message.checkout.recommendation') }}
          </div>
          <div class="items" v-if="canSelectMethods && canSelectMethods.length > 0">
            <div
              @click="chooseMethod(topMethodsIndex[index])"
              class="item"
              v-for="(item, index) in topMethods"
              :key="index"
              :class="{
                active: topMethodsIndex[index] === choosedPaymentMethodIndex
              }"
            >
              <img v-if="item?.cardName === 'CARD'" :src="item.logo" />
              <img v-else :src="`data:image/png;base64,${item.logo}`" />
              <div class="name" v-if="item?.cardName === 'CARD'">Credit/Debit Card</div>
              <div class="name" v-else>{{ item?.cardName }}</div>
            </div>
            <div v-if="canSelectMethods && canSelectMethods.length > 3" class="more" @click="viewAllMethod">...</div>
          </div>
          <div v-else class="description error-tips">
            {{ $t('message.checkout.methodErrorTips') }}
          </div>
        </div>
        <div v-if="choosedPaymentMethod" class="warn-tips">{{ choosedPaymentMethod?.hint }}</div>
        <a-form-model :model="form" :colon="false" :rules="formRules" ref="formRef">
          <div class="payment-info" v-if="canSelectMethods[choosedPaymentMethodIndex]?.cardName === 'CARD'">
            <a-form-model-item prop="cardNumber">
              <template #label>
                <div class="card-label">
                  <span>{{ $t('message.checkout.cardNumber') }}</span>
                  <div class="card-logos">
                    <img
                      class="card-logo"
                      v-for="(card, cardIndex) in orderDetails.creditCardLogos"
                      :key="cardIndex"
                      :src="`data:image/png;base64,${card.logo}`"
                    />
                  </div>
                </div>
              </template>
              <cardnumber
                :cards="orderDetails.creditCardLogos"
                :value.sync="form.cardNumber"
                @focus="onItemFocus()"
                @blur="onItemBlur('cardNumber', form.cardNumber.length)"
                @cardName="onInputCardChange"
              ></cardnumber>
            </a-form-model-item>
            <a-row :gutter="20">
              <a-col :span="12">
                <a-form-model-item :label="$t('message.checkout.cardName')" prop="cardFirstName">
                  <a-input
                    v-model="form.cardFirstName"
                    :size="size"
                    @focus="onItemFocus()"
                    @blur="onItemBlur('cardFirstName', form.cardFirstName.length)"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item class="no-required" :label="' '" prop="cardLastName">
                  <a-input
                    v-model="form.cardLastName"
                    :size="size"
                    @focus="onItemFocus()"
                    @blur="onItemBlur('cardLastName', form.cardLastName.length)"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-form-model-item :label="$t('message.checkout.cvv')" prop="cvv">
              <a-input
                v-model="form.cvv"
                :size="size"
                inputmode="numeric"
                :maxLength="4"
                @input.native="cvvChangeHandler"
                @focus="onItemFocus()"
                @blur="onItemBlur('cvv', form.cvv.length)"
              >
                <template #suffix>
                  <img class="cvv-img" src="../../../../assets/images/checkout/cvv.png" />
                </template>
              </a-input>
            </a-form-model-item>
            <a-form-model-item :label="$t('message.checkout.expiredDate')" prop="expiredDate">
              <a-input
                v-model="form.expiredDate"
                :size="size"
                :maxLength="5"
                placeholder="MM/YY"
                autocomplete="off"
                @input.native="expiredDateChangeHandler"
                @focus="onItemFocus()"
                @blur="onItemBlur('expiredDate', form.expiredDate.length)"
              >
                <template #suffix>
                  <img class="expired-date-img" src="../../../../assets/images/checkout/expired.png" />
                </template>
              </a-input>
            </a-form-model-item>
          </div>
          <!--     bank 子项选择     -->
          <div class="payment-info" v-if="bankList && bankList.length > 0">
            <a-form-model-item
              :label="$t(getSubSelectMethodBackName(canSelectMethods[choosedPaymentMethodIndex]))"
              prop="extension.code"
            >
              <a-select
                v-model="form.extension.code"
                :size="size"
                :showSearch="true"
                :optionFilterProp="'label'"
                @change="onBankChange"
                @focus="onItemFocus()"
                @blur="onItemBlur('extension.code', form.extension.code, 'select')"
              >
                <a-select-option
                  v-for="(country, countryIndex) in bankList"
                  :key="countryIndex"
                  :value="country.code"
                  :label="country.name"
                >
                  {{ country.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <div v-for="(item, index) in bankTemplateList" :key="index">
              <a-form-model-item :label="item?.title" :prop="`extension.${item?.param}`">
                <a-input
                  v-model="form.extension[item?.param]"
                  :size="size"
                  @focus="onItemFocus()"
                  @blur="onItemBlur(`extension.${item?.param}`, form.extension[item?.param].length)"
                />
              </a-form-model-item>
            </div>
          </div>
          <div class="billing-info">
            <div class="billing-title">
              {{ $t('message.checkout.billingInfo') }}
            </div>
            <a-row :gutter="20">
              <a-col :span="!stateList || stateList.length === 0 ? 24 : 12">
                <a-form-model-item :label="$t('message.checkout.country')" prop="country">
                  <a-select
                    v-model="form.country"
                    :size="size"
                    show-search
                    :filterOption="filterCountryOption"
                    @change="onCountryChange"
                    @focus="onItemFocus()"
                    @blur="onItemBlur('country', form.country, 'select')"
                  >
                    <a-select-opt-group>
                      <template #label> </template>
                      <a-select-option
                        v-for="country in putTopCountryList"
                        :key="getCountryNameText(country, app)"
                        :value="country.code"
                        :label="getCountryNameText(country, app)"
                        :title="getCountryNameText(country, app)"
                      >
                        <template v-if="isShowEmoji()"> {{ country.emoji }} </template> {{ getCountryNameText(country, app) }}
                      </a-select-option>
                    </a-select-opt-group>
                    <a-select-opt-group>
                      <template #label>
                        <a-divider class="horizontal-divider"></a-divider>
                      </template>
                      <a-select-option
                        v-for="country in otherCountryList"
                        :key="getCountryNameText(country, app)"
                        :value="country.code"
                        :label="getCountryNameText(country, app)"
                        :title="getCountryNameText(country, app)"
                      >
                        <template v-if="isShowEmoji()"> {{ country.emoji }} </template> {{ getCountryNameText(country, app) }}
                      </a-select-option>
                    </a-select-opt-group>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col v-if="stateList && stateList.length > 0" :span="12">
                <a-form-model-item :label="$t('message.checkout.state')" prop="state">
                  <a-select
                    v-model="form.state"
                    :size="size"
                    show-search
                    :disabled="stateDisable"
                    @focus="onItemFocus()"
                    @blur="onItemBlur('state', form.state, 'select')"
                  >
                    <a-select-option v-for="state in stateList" :key="state" :value="state" :label="state" :title="state">
                      {{ state }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-form-model-item :label="$t('message.checkout.city')" prop="city">
              <a-input v-model="form.city" :size="size" @focus="onItemFocus()" @blur="onItemBlur('city', form.city.length)" />
            </a-form-model-item>
            <a-row :gutter="20">
              <a-col :span="displayItems.streetNumber ? 12 : 24">
                <a-form-model-item :label="$t('message.checkout.address')" prop="address">
                  <a-input
                    v-model="form.address"
                    :size="size"
                    @focus="onItemFocus()"
                    @blur="onItemBlur('address', form.address.length)"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="12" v-if="displayItems.streetNumber">
                <a-form-model-item :label="$t('message.checkout.streetNumber')" prop="streetNumber">
                  <a-input
                    v-model="form.streetNumber"
                    :size="size"
                    @focus="onItemFocus()"
                    @blur="onItemBlur('streetNumber', form.streetNumber.length)"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row :gutter="20">
              <a-col :span="12">
                <a-form-model-item :label="$t('message.checkout.firstName')" prop="firstName">
                  <a-input
                    v-model="form.firstName"
                    :size="size"
                    @focus="onItemFocus()"
                    @blur="onItemBlur('firstName', form.firstName.length)"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="$t('message.checkout.lastName')" prop="lastName">
                  <a-input
                    v-model="form.lastName"
                    :size="size"
                    @focus="onItemFocus()"
                    @blur="onItemBlur('lastName', form.lastName.length)"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-form-model-item
              v-if="displayItems.document && refreshFormItem"
              :label="documentLabel || $t('message.checkout.document')"
              prop="document"
            >
              <a-input
                v-model="form.document"
                :size="size"
                @focus="onItemFocus()"
                @blur="onItemBlur('document', form.document.length)"
              />
            </a-form-model-item>
            <a-form-model-item :label="$t('message.checkout.zipCode')" prop="zipCode">
              <a-input
                v-model="form.zipCode"
                :size="size"
                @focus="onItemFocus()"
                @blur="onItemBlur('zipCode', form.zipCode.length)"
              />
            </a-form-model-item>
            <a-form-model-item :label="$t('message.checkout.email')" prop="email">
              <a-input
                v-model="form.email"
                :size="size"
                @focus="onItemFocus()"
                @blur="onItemBlur('email', form.email.length)"
              />
            </a-form-model-item>
            <a-form-model-item :label="$t('message.checkout.phone')" prop="phone">
              <a-input
                v-model="form.phone"
                :size="size"
                @focus="onItemFocus()"
                @blur="onItemBlur('phone', form.phone.length)"
              />
            </a-form-model-item>
          </div>
        </a-form-model>
        <div>
          <a-button class="button" type="primary" :size="size" @click="submit" :disabled="disable">
            <a-icon type="lock" theme="filled" />
            <span>{{ orderDetails.amount }} {{ $t('message.checkout.pay') }}</span>
          </a-button>
          <a-button @click="cancel" class="return-link" type="link">{{ $t('message.checkout.returnToMerchant') }} </a-button>
        </div>
      </div>
      <div class="order-info">
        <div class="products">
          <div>
            <span class="label">{{ $t('message.checkout.orderId') }}: </span>
            <span class="content">{{ orderDetails.uniqueId }}</span>
          </div>
          <div class="products-list">
            <div class="product-item" v-for="(product, pIndex) in orderDetails.products" :key="pIndex">
              {{ product.name }}
            </div>
          </div>
        </div>
        <div class="info">
          <div class="item">
            <span class="label">{{ $t('message.checkout.orderAmount') }}</span>
            <span class="text amount">{{ orderDetails.amount }} {{ orderDetails.currency }}</span>
          </div>
          <div class="item">
            <span class="label">{{ $t('message.checkout.billingDescriptor') }}</span>
            <span class="text">{{ orderDetails.billingDescription }}</span>
          </div>
        </div>
        <div class="countdown">
          <div class="tip">{{ $t('message.checkout.timeLimit') }}</div>
          <div class="time">{{ initSecondDisplay(Math.floor(countdown / 60)) }} : {{ initSecondDisplay(countdown % 60) }}</div>
        </div>
      </div>
    </div>
    <a-modal
      v-model="viewMorePaymentMethod"
      :title="$t('message.checkout.choosePaymentMethod')"
      :dialogClass="'checkout-dialog'"
      @ok="handleOk"
    >
      <div class="items" v-if="canSelectMethods && canSelectMethods.length > 0">
        <div
          class="item"
          v-for="(method, methodIndex) in canSelectMethods"
          :key="methodIndex"
          @click="selectMethod(methodIndex)"
          :class="{ active: activePaymentMethodIndex === methodIndex }"
        >
          <img v-if="method?.cardName === 'CARD'" class="method-img" :src="method.logo" />
          <img v-else class="method-img" :src="`data:image/png;base64,${method.logo}`" />
          <span v-if="method?.cardName === 'CARD'" class="method-name">Credit/Debit Card</span>
          <span v-else class="method-name">{{ method?.cardName }}</span>
        </div>
      </div>
      <template slot="footer">
        <div class="buttons">
          <a-button key="back" type="link" @click="handleCancel" :size="size">
            {{ $t('message.checkout.later') }}
          </a-button>
          <a-button key="submit" type="primary" :loading="loading" :size="size" @click="handleOk">
            {{ $t('message.checkout.continue') }}
          </a-button>
        </div>
      </template>
    </a-modal>
  </a-spin>
</template>
<script>
import { mapGetters } from 'vuex'
import cardnumber from '@/components/cardnumber'
import Loading from '@/components/loading'
import { getCheckoutData, initFormData, onExpiredDateInput, onSecurityCodeInput } from '@/utils/tools/checkout'
import { confirm } from '@/views/checkout/api'
import Lang from '@/components/lang/index.vue'
import {
  getSubSelectMethodBackName,
  initBankList,
  initCanUseMethod,
  initCountryOptions,
  initExtensionRules,
  initRequiredFields,
  isFilterOption,
  getCountryNameText,
  showDocument,
  initSecondDisplay,
  isShowEmoji,
  stateControl,
  documentControl
} from '@/views/checkout/tools'
import { saveExecuteTime, saveOperation, submitOperationData } from '@/views/checkout/tools/collect'

export default {
  components: { cardnumber, Loading, Lang },
  props: {
    order: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['app'])
  },
  data() {
    return {
      refreshFormItem: true,
      stateList: [],
      stateDisable: false,
      putTopCountryList: [],
      otherCountryList: [],
      orderDetails: this.$props.order,
      canSelectMethods: [],
      topMethods: [],
      topMethodsIndex: [],
      size: 'large',
      documentLabel: '',
      viewMorePaymentMethod: false,
      choosedPaymentMethod: null,
      choosedPaymentMethodIndex: 0,
      activePaymentMethodIndex: 0,
      spinning: false,
      loading: false,
      disable: false,
      form: {
        cardNumber: '',
        cardFirstName: '',
        cardLastName: '',
        cvv: '',
        expiredDate: '',
        country: '',
        city: '',
        state: '',
        firstName: '',
        lastName: '',
        document: '',
        address: '',
        streetNumber: '',
        zipCode: '',
        email: '',
        phone: '',
        // 扩展参数
        extension: {}
      },
      formRules: {},
      countdown: 0,
      orderId: this.$route.params.id,
      displayItems: {},
      // 本地支付银行列表
      bankList: [],
      // 本地支付银行关联表单模板列表
      bankTemplateList: [],
      // 操作收集
      operation: {}
    }
  },
  async created() {
    try {
      const country = this.orderDetails?.billing?.country
      this.onCountryChange(country)
      this.initTopMethods()
      this.choosedPaymentMethod = this.canSelectMethods[this.choosedPaymentMethodIndex]
      this.initCountryList(this.choosedPaymentMethod?.supportCountry)
      initFormData(this.form, this.orderDetails)
      this.bankList = initBankList(this.choosedPaymentMethod, country)
      initRequiredFields(this, this.choosedPaymentMethod)
    } catch (error) {
      console.error(error)
    }
  },
  mounted() {
    saveExecuteTime('startTime', window.initPage)
    saveExecuteTime('completedTime')
  },
  methods: {
    getSubSelectMethodBackName,
    getCountryNameText,
    initSecondDisplay,
    isShowEmoji,
    onItemFocus() {
      this.operation = {
        code: '',
        type: '',
        focus: new Date().getTime() + '',
        blur: '',
        value: ''
      }
    },
    onItemBlur(code, value, type = 'input') {
      document.body.focus()
      this.operation.value = value
      this.operation.type = type
      this.operation.code = code
      this.operation.blur = new Date().getTime() + ''
      saveOperation(this.operation)
    },
    initTopMethods() {
      this.topMethodsIndex = []
      this.topMethods = this.canSelectMethods
      if (this.topMethods.length > 3) {
        this.topMethods = this.topMethods.slice(0, 3)
      }
      for (let i = 0; i < this.topMethods.length; i++) {
        this.topMethodsIndex.push(i)
      }
    },
    initCountryList(list) {
      initCountryOptions(this, list)
    },
    filterCountryOption(value, option) {
      return isFilterOption(value, option)
    },
    onBankChange(value) {
      initExtensionRules(this, value)
    },
    onCountryChange(country) {
      this.documentLabel = showDocument(country)
      this.canSelectMethods = initCanUseMethod(this, country)
      this.initTopMethods()
      this.initMethodChild()
      stateControl(this, country)
      documentControl(this, country)
    },
    expiredDateChangeHandler(evt) {
      this.form.expiredDate = onExpiredDateInput(evt)
    },
    setCountdown(countdown) {
      this.countdown = countdown
    },
    cvvChangeHandler(evt) {
      this.form.cvv = onSecurityCodeInput(evt)
    },
    onInputCardChange(card) {
      this.disable = !!this.orderDetails?.creditCardLogos.every((item) => item.code !== card)
    },
    submit() {
      saveExecuteTime('subTime')
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          this.spinning = true
          const submitData = getCheckoutData(this.form, this.canSelectMethods[this.choosedPaymentMethodIndex])
          try {
            const res = await confirm(this.orderId, submitData)
            if (res.status === 301) {
              window.location.href = res.redirectUrl
              return
            }
            let name = ''
            if (res.status === 100) {
              name = 'CheckoutSuccess'
            } else if (res.status === 102) {
              name = 'CheckoutPending'
            } else {
              name = 'CheckoutFailed'
            }
            this.$router.push({
              name,
              params: { id: this.orderId }
            })
            this.spinning = false
            submitOperationData()
          } catch (error) {
            submitOperationData()
            this.spinning = false
            this.$router.push({
              name: 'CheckoutFailed',
              params: { id: this.orderId }
            })
            console.log('confirm error', error)
          }
        }
      })
    },
    cancel() {
      window.location.href = this.orderDetails.cancelUrl
    },
    viewAllMethod() {
      this.activePaymentMethodIndex = this.choosedPaymentMethodIndex
      this.viewMorePaymentMethod = true
    },
    selectMethod(index) {
      this.activePaymentMethodIndex = index
    },
    initMethodChild() {
      this.form.extension.code = ''
      this.bankList = []
      // eslint-disable-next-line no-unused-expressions
      this.$refs.formRef?.clearValidate()
    },
    chooseMethod(index) {
      this.choosedPaymentMethodIndex = index
      this.activePaymentMethodIndex = index
      this.choosedPaymentMethod = this.canSelectMethods[this.choosedPaymentMethodIndex]
      this.initMethodChild()
      this.bankList = initBankList(this.choosedPaymentMethod, this.form.country)
      initRequiredFields(this, this.choosedPaymentMethod)
      this.initCountryList(this.choosedPaymentMethod?.supportCountry)
    },
    handleOk() {
      // this.loading = true;
      this.choosedPaymentMethodIndex = this.activePaymentMethodIndex
      this.choosedPaymentMethod = this.canSelectMethods[this.choosedPaymentMethodIndex]
      if (this.topMethodsIndex.indexOf(this.activePaymentMethodIndex) < 0) {
        this.topMethodsIndex[this.topMethodsIndex.length - 1] = this.activePaymentMethodIndex
        this.topMethods[this.topMethods.length - 1] = this.canSelectMethods[this.activePaymentMethodIndex]
      }
      this.initMethodChild()
      this.bankList = initBankList(this.choosedPaymentMethod, this.form.country)
      initRequiredFields(this, this.choosedPaymentMethod)
      this.initCountryList(this.choosedPaymentMethod?.supportCountry)
      this.viewMorePaymentMethod = false
      // setTimeout(() => {
      //   this.loading = false;
      // }, 500);
    },
    handleCancel() {
      this.viewMorePaymentMethod = false
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@/styles/common.less';

.pc {
  display: flex;
  justify-content: space-between;
  max-width: 900px;
  margin: 0 auto;
  padding-top: 67px;
  flex-wrap: wrap;

  .warn-tips {
    color: @warn-color;
    margin-top: -10px;
    margin-bottom: 15px
  }

  .form-info {
    background-color: @white;
    width: 540px;
    padding: 35px 40px;

    .title-lang {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
    }

    .title {
      #Font.heavy();
      font-size: 20px;
      line-height: 27px;
    }

    .description {
      font-size: 14px;
      line-height: 19px;
      margin-top: 2px;
    }

    .methods {
      margin-top: 25px;
      padding-bottom: 25px;

      .method-title {
        font-size: 14px;
        font-weight: Heavy;
        line-height: 19px;
      }

      .items {
        display: flex;
        justify-content: flex-start;
        margin-top: 8px;
        gap: 10px;

        .item {
          border: 1.5px solid @checkout-border-color;
          border-radius: 4px;
          width: 130px;
          height: 65px;
          padding: 7px 12px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          cursor: pointer;

          &.active {
            border-color: @checkout-text-color;
          }

          img {
            height: 20px;
            width: fit-content;
          }

          .name {
            #Font.heavy();
            font-size: 12px;
          }
        }

        .more {
          border: 1.5px solid @checkout-text-color;
          border-radius: 4px;
          width: 35px;
          height: 65px;
          font-size: 40px;
          line-height: 15px;
          text-align: center;
          letter-spacing: -1px;
          color: @checkout-more-color;
          cursor: pointer;
        }
      }
    }

    .payment-info {
      .cvv-img {
        width: 45px;
      }

      .expired-date-img {
        width: 45px;
      }

      .card-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .card-logos {
          display: flex;
          gap: 5px;

          .card-logo {
            height: 20px;
          }
        }
      }
    }

    .billing-info {
      .billing-title {
        #Font.heavy();
        font-size: 16px;
        line-height: 22px;
        color: @checkout-text-color;
        padding-bottom: 17px;
      }
    }
  }

  .button {
    width: 100%;
    #Font.heavy();
  }

  .return-link {
    padding: 0;
    #Font.regular();
    font-size: 14px;
    margin-top: 20px;
  }

  .order-info {
    background-color: #fff;
    width: 345px;
    padding: 10px 10px 32px;
    height: fit-content;

    .products {
      background: @checkout-bg;
      padding: 12px;

      .label {
        #Font.regular();
        font-size: 14px;
        line-height: 19px;
      }

      .content {
        #Font.heavy();
        font-size: 14px;
        line-height: 19px;
      }

      .products-list {
        margin-top: 15px;

        .products-item {
          #Font.regular();
          font-size: 14px;
          line-height: 19px;
        }
      }
    }

    .info {
      padding: 18px 12px 33px;

      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 9px 0;

        .label {
          #Font.regular();
          font-size: 14px;
          line-height: 19px;
        }

        .text {
          #Font.heavy();
          font-size: 14px;
          line-height: 19px;

          &.amount {
            font-size: 18px;
            line-height: 25px;
            color: @checkout-hight-text-color;
          }
        }
      }
    }

    .countdown {
      padding: 0 12px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 25px;

      .tip {
        #Font.regular();
        font-size: 14px;
        line-height: 19px;
      }

      .time {
        background: @checkout-bg;
        width: 75px;
        min-width: 75px;
        height: 34px;
        line-height: 34px;
        #Font.heavy();
        font-size: 14px;
        text-align: center;
        white-space: nowrap;
        margin-top: 4px;
      }
    }
  }
}

.checkout-dialog {
  .items {
    .item {
      border-radius: 4px;
      height: 65px;
      display: flex;
      margin-top: 7px;
      align-items: center;
      padding: 0 12px;
      cursor: pointer;

      &:first-child {
        margin-top: 0;
      }

      &.active {
        background: #f1f5f7;
      }

      .method-img {
        width: 50px;
        height: fit-content;
        margin-right: 28px;
      }

      .method-name {
        #Font.heavy();
        font-size: 18px;
        line-height: 25px;
        color: @checkout-text-color;
      }
    }
  }
}
</style>
