export default [
  {
    name: 'Afghanistan',
    code: 'AF',
    translations: {
      kr: '아프가니스탄',
      'pt-BR': 'Afeganistão',
      pt: 'Afeganistão',
      nl: 'Afghanistan',
      hr: 'Afganistan',
      fa: 'افغانستان',
      de: 'Afghanistan',
      es: 'Afganistán',
      fr: 'Afghanistan',
      ja: 'アフガニスタン',
      it: 'Afghanistan',
      'zh-cn': '阿富汗',
      tr: 'Afganistan',
      ru: 'Афганистан',
      uk: 'Афганістан',
      pl: 'Afganistan'
    },
    emoji: '🇦🇫'
  },
  {
    name: 'Aland Islands',
    code: 'AX',
    translations: {
      kr: '올란드 제도',
      'pt-BR': 'Ilhas de Aland',
      pt: 'Ilhas de Aland',
      nl: 'Ålandeilanden',
      hr: 'Ålandski otoci',
      fa: 'جزایر الند',
      de: 'Åland',
      es: 'Alandia',
      fr: 'Åland',
      ja: 'オーランド諸島',
      it: 'Isole Aland',
      'zh-cn': '奥兰群岛',
      tr: 'Åland Adalari',
      ru: 'Аландские острова',
      uk: 'Аландські острови',
      pl: 'Wyspy Alandzkie'
    },
    emoji: '🇦🇽'
  },
  {
    name: 'Albania',
    code: 'AL',
    translations: {
      kr: '알바니아',
      'pt-BR': 'Albânia',
      pt: 'Albânia',
      nl: 'Albanië',
      hr: 'Albanija',
      fa: 'آلبانی',
      de: 'Albanien',
      es: 'Albania',
      fr: 'Albanie',
      ja: 'アルバニア',
      it: 'Albania',
      'zh-cn': '阿尔巴尼亚',
      tr: 'Arnavutluk',
      ru: 'Албания',
      uk: 'Албанія',
      pl: 'Albania'
    },
    emoji: '🇦🇱'
  },
  {
    name: 'Algeria',
    code: 'DZ',
    translations: {
      kr: '알제리',
      'pt-BR': 'Argélia',
      pt: 'Argélia',
      nl: 'Algerije',
      hr: 'Alžir',
      fa: 'الجزایر',
      de: 'Algerien',
      es: 'Argelia',
      fr: 'Algérie',
      ja: 'アルジェリア',
      it: 'Algeria',
      'zh-cn': '阿尔及利亚',
      tr: 'Cezayir',
      ru: 'Алжир',
      uk: 'Алжир',
      pl: 'Algieria'
    },
    emoji: '🇩🇿'
  },
  {
    name: 'American Samoa',
    code: 'AS',
    translations: {
      kr: '아메리칸사모아',
      'pt-BR': 'Samoa Americana',
      pt: 'Samoa Americana',
      nl: 'Amerikaans Samoa',
      hr: 'Američka Samoa',
      fa: 'ساموآی آمریکا',
      de: 'Amerikanisch-Samoa',
      es: 'Samoa Americana',
      fr: 'Samoa américaines',
      ja: 'アメリカ領サモア',
      it: 'Samoa Americane',
      'zh-cn': '美属萨摩亚',
      tr: 'Amerikan Samoasi',
      ru: 'Американское Самоа',
      uk: 'Американське Самоа',
      pl: 'Samoa Amerykańskie'
    },
    emoji: '🇦🇸'
  },
  {
    name: 'Andorra',
    code: 'AD',
    translations: {
      kr: '안도라',
      'pt-BR': 'Andorra',
      pt: 'Andorra',
      nl: 'Andorra',
      hr: 'Andora',
      fa: 'آندورا',
      de: 'Andorra',
      es: 'Andorra',
      fr: 'Andorre',
      ja: 'アンドラ',
      it: 'Andorra',
      'zh-cn': '安道尔',
      tr: 'Andorra',
      ru: 'Андорра',
      uk: 'Андорра',
      pl: 'Andora'
    },
    emoji: '🇦🇩'
  },
  {
    name: 'Angola',
    code: 'AO',
    translations: {
      kr: '앙골라',
      'pt-BR': 'Angola',
      pt: 'Angola',
      nl: 'Angola',
      hr: 'Angola',
      fa: 'آنگولا',
      de: 'Angola',
      es: 'Angola',
      fr: 'Angola',
      ja: 'アンゴラ',
      it: 'Angola',
      'zh-cn': '安哥拉',
      tr: 'Angola',
      ru: 'Ангола',
      uk: 'Ангола',
      pl: 'Angola'
    },
    emoji: '🇦🇴'
  },
  {
    name: 'Anguilla',
    code: 'AI',
    translations: {
      kr: '앵귈라',
      'pt-BR': 'Anguila',
      pt: 'Anguila',
      nl: 'Anguilla',
      hr: 'Angvila',
      fa: 'آنگویلا',
      de: 'Anguilla',
      es: 'Anguilla',
      fr: 'Anguilla',
      ja: 'アンギラ',
      it: 'Anguilla',
      'zh-cn': '安圭拉',
      tr: 'Anguilla',
      ru: 'Ангилья',
      uk: 'Ангілья',
      pl: 'Anguilla'
    },
    emoji: '🇦🇮'
  },
  {
    name: 'Antarctica',
    code: 'AQ',
    translations: {
      kr: '남극',
      'pt-BR': 'Antártida',
      pt: 'Antárctida',
      nl: 'Antarctica',
      hr: 'Antarktika',
      fa: 'جنوبگان',
      de: 'Antarktika',
      es: 'Antártida',
      fr: 'Antarctique',
      ja: '南極大陸',
      it: 'Antartide',
      'zh-cn': '南极洲',
      tr: 'Antartika',
      ru: 'Антарктида',
      uk: 'Антарктида',
      pl: 'Antarktyda'
    },
    emoji: '🇦🇶'
  },
  {
    name: 'Antigua and Barbuda',
    code: 'AG',
    translations: {
      kr: '앤티가 바부다',
      'pt-BR': 'Antígua e Barbuda',
      pt: 'Antígua e Barbuda',
      nl: 'Antigua en Barbuda',
      hr: 'Antigva i Barbuda',
      fa: 'آنتیگوا و باربودا',
      de: 'Antigua und Barbuda',
      es: 'Antigua y Barbuda',
      fr: 'Antigua-et-Barbuda',
      ja: 'アンティグア・バーブーダ',
      it: 'Antigua e Barbuda',
      'zh-cn': '安提瓜和巴布达',
      tr: 'Antigua Ve Barbuda',
      ru: 'Антигуа и Барбуда',
      uk: 'Антигуа і Барбуда',
      pl: 'Antigua i Barbuda'
    },
    emoji: '🇦🇬'
  },
  {
    name: 'Argentina',
    code: 'AR',
    translations: {
      kr: '아르헨티나',
      'pt-BR': 'Argentina',
      pt: 'Argentina',
      nl: 'Argentinië',
      hr: 'Argentina',
      fa: 'آرژانتین',
      de: 'Argentinien',
      es: 'Argentina',
      fr: 'Argentine',
      ja: 'アルゼンチン',
      it: 'Argentina',
      'zh-cn': '阿根廷',
      tr: 'Arjantin',
      ru: 'Аргентина',
      uk: 'Аргентина',
      pl: 'Argentyna'
    },
    emoji: '🇦🇷'
  },
  {
    name: 'Armenia',
    code: 'AM',
    translations: {
      kr: '아르메니아',
      'pt-BR': 'Armênia',
      pt: 'Arménia',
      nl: 'Armenië',
      hr: 'Armenija',
      fa: 'ارمنستان',
      de: 'Armenien',
      es: 'Armenia',
      fr: 'Arménie',
      ja: 'アルメニア',
      it: 'Armenia',
      'zh-cn': '亚美尼亚',
      tr: 'Ermenistan',
      ru: 'Армения',
      uk: 'Вірменія',
      pl: 'Armenia'
    },
    emoji: '🇦🇲'
  },
  {
    name: 'Aruba',
    code: 'AW',
    translations: {
      kr: '아루바',
      'pt-BR': 'Aruba',
      pt: 'Aruba',
      nl: 'Aruba',
      hr: 'Aruba',
      fa: 'آروبا',
      de: 'Aruba',
      es: 'Aruba',
      fr: 'Aruba',
      ja: 'アルバ',
      it: 'Aruba',
      'zh-cn': '阿鲁巴',
      tr: 'Aruba',
      ru: 'Аруба',
      uk: 'Аруба',
      pl: 'Aruba'
    },
    emoji: '🇦🇼'
  },
  {
    name: 'Australia',
    code: 'AU',
    translations: {
      kr: '호주',
      'pt-BR': 'Austrália',
      pt: 'Austrália',
      nl: 'Australië',
      hr: 'Australija',
      fa: 'استرالیا',
      de: 'Australien',
      es: 'Australia',
      fr: 'Australie',
      ja: 'オーストラリア',
      it: 'Australia',
      'zh-cn': '澳大利亚',
      tr: 'Avustralya',
      ru: 'Австралия',
      uk: 'Австралія',
      pl: 'Australia'
    },
    emoji: '🇦🇺'
  },
  {
    name: 'Austria',
    code: 'AT',
    translations: {
      kr: '오스트리아',
      'pt-BR': 'áustria',
      pt: 'áustria',
      nl: 'Oostenrijk',
      hr: 'Austrija',
      fa: 'اتریش',
      de: 'Österreich',
      es: 'Austria',
      fr: 'Autriche',
      ja: 'オーストリア',
      it: 'Austria',
      'zh-cn': '奥地利',
      tr: 'Avusturya',
      ru: 'Австрия',
      uk: 'Австрія',
      pl: 'Austria'
    },
    emoji: '🇦🇹'
  },
  {
    name: 'Azerbaijan',
    code: 'AZ',
    translations: {
      kr: '아제르바이잔',
      'pt-BR': 'Azerbaijão',
      pt: 'Azerbaijão',
      nl: 'Azerbeidzjan',
      hr: 'Azerbajdžan',
      fa: 'آذربایجان',
      de: 'Aserbaidschan',
      es: 'Azerbaiyán',
      fr: 'Azerbaïdjan',
      ja: 'アゼルバイジャン',
      it: 'Azerbaijan',
      'zh-cn': '阿塞拜疆',
      tr: 'Azerbaycan',
      ru: 'Азербайджан',
      uk: 'Азербайджан',
      pl: 'Azerbejdżan'
    },
    emoji: '🇦🇿'
  },
  {
    name: 'Bahrain',
    code: 'BH',
    translations: {
      kr: '바레인',
      'pt-BR': 'Bahrein',
      pt: 'Barém',
      nl: 'Bahrein',
      hr: 'Bahrein',
      fa: 'بحرین',
      de: 'Bahrain',
      es: 'Bahrein',
      fr: 'Bahreïn',
      ja: 'バーレーン',
      it: 'Bahrein',
      'zh-cn': '巴林',
      tr: 'Bahreyn',
      ru: 'Бахрейн',
      uk: 'Бахрейн',
      pl: 'Bahrajn'
    },
    emoji: '🇧🇭'
  },
  {
    name: 'Bangladesh',
    code: 'BD',
    translations: {
      kr: '방글라데시',
      'pt-BR': 'Bangladesh',
      pt: 'Bangladeche',
      nl: 'Bangladesh',
      hr: 'Bangladeš',
      fa: 'بنگلادش',
      de: 'Bangladesch',
      es: 'Bangladesh',
      fr: 'Bangladesh',
      ja: 'バングラデシュ',
      it: 'Bangladesh',
      'zh-cn': '孟加拉',
      tr: 'Bangladeş',
      ru: 'Бангладеш',
      uk: 'Бангладеш',
      pl: 'Bangladesz'
    },
    emoji: '🇧🇩'
  },
  {
    name: 'Barbados',
    code: 'BB',
    translations: {
      kr: '바베이도스',
      'pt-BR': 'Barbados',
      pt: 'Barbados',
      nl: 'Barbados',
      hr: 'Barbados',
      fa: 'باربادوس',
      de: 'Barbados',
      es: 'Barbados',
      fr: 'Barbade',
      ja: 'バルバドス',
      it: 'Barbados',
      'zh-cn': '巴巴多斯',
      tr: 'Barbados',
      ru: 'Барбадос',
      uk: 'Барбадос',
      pl: 'Barbados'
    },
    emoji: '🇧🇧'
  },
  {
    name: 'Belarus',
    code: 'BY',
    translations: {
      kr: '벨라루스',
      'pt-BR': 'Bielorrússia',
      pt: 'Bielorrússia',
      nl: 'Wit-Rusland',
      hr: 'Bjelorusija',
      fa: 'بلاروس',
      de: 'Weißrussland',
      es: 'Bielorrusia',
      fr: 'Biélorussie',
      ja: 'ベラルーシ',
      it: 'Bielorussia',
      'zh-cn': '白俄罗斯',
      tr: 'Belarus',
      ru: 'Беларусь',
      uk: 'Білорусь',
      pl: 'Białoruś'
    },
    emoji: '🇧🇾'
  },
  {
    name: 'Belgium',
    code: 'BE',
    translations: {
      kr: '벨기에',
      'pt-BR': 'Bélgica',
      pt: 'Bélgica',
      nl: 'België',
      hr: 'Belgija',
      fa: 'بلژیک',
      de: 'Belgien',
      es: 'Bélgica',
      fr: 'Belgique',
      ja: 'ベルギー',
      it: 'Belgio',
      'zh-cn': '比利时',
      tr: 'Belçika',
      ru: 'Бельгия',
      uk: 'Бельгія',
      pl: 'Belgia'
    },
    emoji: '🇧🇪'
  },
  {
    name: 'Belize',
    code: 'BZ',
    translations: {
      kr: '벨리즈',
      'pt-BR': 'Belize',
      pt: 'Belize',
      nl: 'Belize',
      hr: 'Belize',
      fa: 'بلیز',
      de: 'Belize',
      es: 'Belice',
      fr: 'Belize',
      ja: 'ベリーズ',
      it: 'Belize',
      'zh-cn': '伯利兹',
      tr: 'Belize',
      ru: 'Белиз',
      uk: 'Беліз',
      pl: 'Belize'
    },
    emoji: '🇧🇿'
  },
  {
    name: 'Benin',
    code: 'BJ',
    translations: {
      kr: '베냉',
      'pt-BR': 'Benin',
      pt: 'Benim',
      nl: 'Benin',
      hr: 'Benin',
      fa: 'بنین',
      de: 'Benin',
      es: 'Benín',
      fr: 'Bénin',
      ja: 'ベナン',
      it: 'Benin',
      'zh-cn': '贝宁',
      tr: 'Benin',
      ru: 'Бенин',
      uk: 'Бенін',
      pl: 'Benin'
    },
    emoji: '🇧🇯'
  },
  {
    name: 'Bermuda',
    code: 'BM',
    translations: {
      kr: '버뮤다',
      'pt-BR': 'Bermudas',
      pt: 'Bermudas',
      nl: 'Bermuda',
      hr: 'Bermudi',
      fa: 'برمودا',
      de: 'Bermuda',
      es: 'Bermudas',
      fr: 'Bermudes',
      ja: 'バミューダ',
      it: 'Bermuda',
      'zh-cn': '百慕大',
      tr: 'Bermuda',
      ru: 'Бермуды',
      uk: 'Бермудські острови',
      pl: 'Bermudy'
    },
    emoji: '🇧🇲'
  },
  {
    name: 'Bhutan',
    code: 'BT',
    translations: {
      kr: '부탄',
      'pt-BR': 'Butão',
      pt: 'Butão',
      nl: 'Bhutan',
      hr: 'Butan',
      fa: 'بوتان',
      de: 'Bhutan',
      es: 'Bután',
      fr: 'Bhoutan',
      ja: 'ブータン',
      it: 'Bhutan',
      'zh-cn': '不丹',
      tr: 'Butan',
      ru: 'Бутан',
      uk: 'Бутан',
      pl: 'Bhutan'
    },
    emoji: '🇧🇹'
  },
  {
    name: 'Bolivia',
    code: 'BO',
    translations: {
      kr: '볼리비아',
      'pt-BR': 'Bolívia',
      pt: 'Bolívia',
      nl: 'Bolivia',
      hr: 'Bolivija',
      fa: 'بولیوی',
      de: 'Bolivien',
      es: 'Bolivia',
      fr: 'Bolivie',
      ja: 'ボリビア多民族国',
      it: 'Bolivia',
      'zh-cn': '玻利维亚',
      tr: 'Bolivya',
      ru: 'Боливия',
      uk: 'Болівія',
      pl: 'Boliwia'
    },
    emoji: '🇧🇴'
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    code: 'BQ',
    translations: {
      kr: '보네르 섬',
      'pt-BR': 'Bonaire',
      pt: 'Bonaire',
      fa: 'بونیر',
      de: 'Bonaire, Sint Eustatius und Saba',
      fr: 'Bonaire, Saint-Eustache et Saba',
      it: 'Bonaire, Saint-Eustache e Saba',
      'zh-cn': '博内尔岛、圣尤斯特歇斯和萨巴岛',
      tr: 'Karayip Hollandasi',
      ru: 'Бонайре, Синт-Эстатиус и Саба',
      uk: 'Бонайре, Сент-Естатіус і Саба',
      pl: 'Bonaire, Sint Eustatius i Saba'
    },
    emoji: '🇧🇶'
  },
  {
    name: 'Bosnia and Herzegovina',
    code: 'BA',
    translations: {
      kr: '보스니아 헤르체고비나',
      'pt-BR': 'Bósnia e Herzegovina',
      pt: 'Bósnia e Herzegovina',
      nl: 'Bosnië en Herzegovina',
      hr: 'Bosna i Hercegovina',
      fa: 'بوسنی و هرزگوین',
      de: 'Bosnien und Herzegowina',
      es: 'Bosnia y Herzegovina',
      fr: 'Bosnie-Herzégovine',
      ja: 'ボスニア・ヘルツェゴビナ',
      it: 'Bosnia ed Erzegovina',
      'zh-cn': '波斯尼亚和黑塞哥维那',
      tr: 'Bosna Hersek',
      ru: 'Босния и Герцеговина',
      uk: 'Боснія і Герцеговина',
      pl: 'Bośnia i Hercegowina'
    },
    emoji: '🇧🇦'
  },
  {
    name: 'Botswana',
    code: 'BW',
    translations: {
      kr: '보츠와나',
      'pt-BR': 'Botsuana',
      pt: 'Botsuana',
      nl: 'Botswana',
      hr: 'Bocvana',
      fa: 'بوتسوانا',
      de: 'Botswana',
      es: 'Botswana',
      fr: 'Botswana',
      ja: 'ボツワナ',
      it: 'Botswana',
      'zh-cn': '博茨瓦纳',
      tr: 'Botsvana',
      ru: 'Ботсвана',
      uk: 'Ботсвана',
      pl: 'Botswana'
    },
    emoji: '🇧🇼'
  },
  {
    name: 'Bouvet Island',
    code: 'BV',
    translations: {
      kr: '부벳 섬',
      'pt-BR': 'Ilha Bouvet',
      pt: 'Ilha Bouvet',
      nl: 'Bouveteiland',
      hr: 'Otok Bouvet',
      fa: 'جزیره بووه',
      de: 'Bouvetinsel',
      es: 'Isla Bouvet',
      fr: 'Île Bouvet',
      ja: 'ブーベ島',
      it: 'Isola Bouvet',
      'zh-cn': '布维岛',
      tr: 'Bouvet Adasi',
      ru: 'Остров Буве',
      uk: 'Острів Буве',
      pl: 'Wyspa Bouveta'
    },
    emoji: '🇧🇻'
  },
  {
    name: 'Brazil',
    code: 'BR',
    translations: {
      kr: '브라질',
      'pt-BR': 'Brasil',
      pt: 'Brasil',
      nl: 'Brazilië',
      hr: 'Brazil',
      fa: 'برزیل',
      de: 'Brasilien',
      es: 'Brasil',
      fr: 'Brésil',
      ja: 'ブラジル',
      it: 'Brasile',
      'zh-cn': '巴西',
      tr: 'Brezilya',
      ru: 'Бразилия',
      uk: 'Бразилія',
      pl: 'Brazylia'
    },
    emoji: '🇧🇷'
  },
  {
    name: 'British Indian Ocean Territory',
    code: 'IO',
    translations: {
      kr: '영국령 인도양 지역',
      'pt-BR': 'Território Britânico do Oceano íÍdico',
      pt: 'Território Britânico do Oceano Índico',
      nl: 'Britse Gebieden in de Indische Oceaan',
      hr: 'Britanski Indijskooceanski teritorij',
      fa: 'قلمرو بریتانیا در اقیانوس هند',
      de: 'Britisches Territorium im Indischen Ozean',
      es: 'Territorio Británico del Océano Índico',
      fr: "Territoire britannique de l'océan Indien",
      ja: 'イギリス領インド洋地域',
      it: "Territorio britannico dell'oceano indiano",
      'zh-cn': '英属印度洋领地',
      tr: 'Britanya Hint Okyanusu Topraklari',
      ru: 'Британская территория в Индийском океане',
      uk: 'Британська територія в Індійському океані',
      pl: 'Brytyjskie Terytorium Oceanu Indyjskiego'
    },
    emoji: '🇮🇴'
  },
  {
    name: 'Brunei',
    code: 'BN',
    translations: {
      kr: '브루나이',
      'pt-BR': 'Brunei',
      pt: 'Brunei',
      nl: 'Brunei',
      hr: 'Brunej',
      fa: 'برونئی',
      de: 'Brunei',
      es: 'Brunei',
      fr: 'Brunei',
      ja: 'ブルネイ・ダルサラーム',
      it: 'Brunei',
      'zh-cn': '文莱',
      tr: 'Brunei',
      ru: 'Бруней',
      uk: 'Бруней',
      pl: 'Brunei'
    },
    emoji: '🇧🇳'
  },
  {
    name: 'Bulgaria',
    code: 'BG',
    translations: {
      kr: '불가리아',
      'pt-BR': 'Bulgária',
      pt: 'Bulgária',
      nl: 'Bulgarije',
      hr: 'Bugarska',
      fa: 'بلغارستان',
      de: 'Bulgarien',
      es: 'Bulgaria',
      fr: 'Bulgarie',
      ja: 'ブルガリア',
      it: 'Bulgaria',
      'zh-cn': '保加利亚',
      tr: 'Bulgaristan',
      ru: 'Болгария',
      uk: 'Болгарія',
      pl: 'Bułgaria'
    },
    emoji: '🇧🇬'
  },
  {
    name: 'Burkina Faso',
    code: 'BF',
    translations: {
      kr: '부르키나 파소',
      'pt-BR': 'Burkina Faso',
      pt: 'Burquina Faso',
      nl: 'Burkina Faso',
      hr: 'Burkina Faso',
      fa: 'بورکینافاسو',
      de: 'Burkina Faso',
      es: 'Burkina Faso',
      fr: 'Burkina Faso',
      ja: 'ブルキナファソ',
      it: 'Burkina Faso',
      'zh-cn': '布基纳法索',
      tr: 'Burkina Faso',
      ru: 'Буркина-Фасо',
      uk: 'Буркіна-Фасо',
      pl: 'Burkina Faso'
    },
    emoji: '🇧🇫'
  },
  {
    name: 'Burundi',
    code: 'BI',
    translations: {
      kr: '부룬디',
      'pt-BR': 'Burundi',
      pt: 'Burúndi',
      nl: 'Burundi',
      hr: 'Burundi',
      fa: 'بوروندی',
      de: 'Burundi',
      es: 'Burundi',
      fr: 'Burundi',
      ja: 'ブルンジ',
      it: 'Burundi',
      'zh-cn': '布隆迪',
      tr: 'Burundi',
      ru: 'Бурунди',
      uk: 'Бурунді',
      pl: 'Burundi'
    },
    emoji: '🇧🇮'
  },
  {
    name: 'Cambodia',
    code: 'KH',
    translations: {
      kr: '캄보디아',
      'pt-BR': 'Camboja',
      pt: 'Camboja',
      nl: 'Cambodja',
      hr: 'Kambodža',
      fa: 'کامبوج',
      de: 'Kambodscha',
      es: 'Camboya',
      fr: 'Cambodge',
      ja: 'カンボジア',
      it: 'Cambogia',
      'zh-cn': '柬埔寨',
      tr: 'Kamboçya',
      ru: 'Камбоджа',
      uk: 'Камбоджа',
      pl: 'Kambodża'
    },
    emoji: '🇰🇭'
  },
  {
    name: 'Cameroon',
    code: 'CM',
    translations: {
      kr: '카메룬',
      'pt-BR': 'Camarões',
      pt: 'Camarões',
      nl: 'Kameroen',
      hr: 'Kamerun',
      fa: 'کامرون',
      de: 'Kamerun',
      es: 'Camerún',
      fr: 'Cameroun',
      ja: 'カメルーン',
      it: 'Camerun',
      'zh-cn': '喀麦隆',
      tr: 'Kamerun',
      ru: 'Камерун',
      uk: 'Камерун',
      pl: 'Kamerun'
    },
    emoji: '🇨🇲'
  },
  {
    name: 'Canada',
    code: 'CA',
    translations: {
      kr: '캐나다',
      'pt-BR': 'Canadá',
      pt: 'Canadá',
      nl: 'Canada',
      hr: 'Kanada',
      fa: 'کانادا',
      de: 'Kanada',
      es: 'Canadá',
      fr: 'Canada',
      ja: 'カナダ',
      it: 'Canada',
      'zh-cn': '加拿大',
      tr: 'Kanada',
      ru: 'Канада',
      uk: 'Канада',
      pl: 'Kanada'
    },
    emoji: '🇨🇦'
  },
  {
    name: 'Cape Verde',
    code: 'CV',
    translations: {
      kr: '카보베르데',
      'pt-BR': 'Cabo Verde',
      pt: 'Cabo Verde',
      nl: 'Kaapverdië',
      hr: 'Zelenortska Republika',
      fa: 'کیپ ورد',
      de: 'Kap Verde',
      es: 'Cabo Verde',
      fr: 'Cap Vert',
      ja: 'カーボベルデ',
      it: 'Capo Verde',
      'zh-cn': '佛得角',
      tr: 'Cabo Verde',
      ru: 'Кабо-Верде',
      uk: 'Кабо-Верде',
      pl: 'Republika Zielonego Przylądka'
    },
    emoji: '🇨🇻'
  },
  {
    name: 'Cayman Islands',
    code: 'KY',
    translations: {
      kr: '케이먼 제도',
      'pt-BR': 'Ilhas Cayman',
      pt: 'Ilhas Caimão',
      nl: 'Caymaneilanden',
      hr: 'Kajmanski otoci',
      fa: 'جزایر کیمن',
      de: 'Kaimaninseln',
      es: 'Islas Caimán',
      fr: 'Îles Caïmans',
      ja: 'ケイマン諸島',
      it: 'Isole Cayman',
      'zh-cn': '开曼群岛',
      tr: 'Cayman Adalari',
      ru: 'Каймановы острова',
      uk: 'Кайманові острови',
      pl: 'Kajmany'
    },
    emoji: '🇰🇾'
  },
  {
    name: 'Central African Republic',
    code: 'CF',
    translations: {
      kr: '중앙아프리카 공화국',
      'pt-BR': 'República Centro-Africana',
      pt: 'República Centro-Africana',
      nl: 'Centraal-Afrikaanse Republiek',
      hr: 'Srednjoafrička Republika',
      fa: 'جمهوری آفریقای مرکزی',
      de: 'Zentralafrikanische Republik',
      es: 'República Centroafricana',
      fr: 'République centrafricaine',
      ja: '中央アフリカ共和国',
      it: 'Repubblica Centrafricana',
      'zh-cn': '中非',
      tr: 'Orta Afrika Cumhuriyeti',
      ru: 'Центральноафриканская Республика',
      uk: 'Центральноафриканська Республіка',
      pl: 'Republika Środkowoafrykańska'
    },
    emoji: '🇨🇫'
  },
  {
    name: 'Chad',
    code: 'TD',
    translations: {
      kr: '차드',
      'pt-BR': 'Chade',
      pt: 'Chade',
      nl: 'Tsjaad',
      hr: 'Čad',
      fa: 'چاد',
      de: 'Tschad',
      es: 'Chad',
      fr: 'Tchad',
      ja: 'チャド',
      it: 'Ciad',
      'zh-cn': '乍得',
      tr: 'Çad',
      ru: 'Чад',
      uk: 'Чад.',
      pl: 'Czad'
    },
    emoji: '🇹🇩'
  },
  {
    name: 'Chile',
    code: 'CL',
    translations: {
      kr: '칠리',
      'pt-BR': 'Chile',
      pt: 'Chile',
      nl: 'Chili',
      hr: 'Čile',
      fa: 'شیلی',
      de: 'Chile',
      es: 'Chile',
      fr: 'Chili',
      ja: 'チリ',
      it: 'Cile',
      'zh-cn': '智利',
      tr: 'Şili',
      ru: 'Чили',
      uk: 'Чилі',
      pl: 'Chile'
    },
    emoji: '🇨🇱'
  },
  {
    name: 'China',
    code: 'CN',
    translations: {
      kr: '중국',
      'pt-BR': 'China',
      pt: 'China',
      nl: 'China',
      hr: 'Kina',
      fa: 'چین',
      de: 'China',
      es: 'China',
      fr: 'Chine',
      ja: '中国',
      it: 'Cina',
      'zh-cn': '中国',
      tr: 'Çin',
      ru: 'Китай',
      uk: 'Китай',
      pl: 'Chiny'
    },
    emoji: '🇨🇳'
  },
  {
    name: 'Christmas Island',
    code: 'CX',
    translations: {
      kr: '크리스마스 섬',
      'pt-BR': 'Ilha Christmas',
      pt: 'Ilha do Natal',
      nl: 'Christmaseiland',
      hr: 'Božićni otok',
      fa: 'جزیره کریسمس',
      de: 'Weihnachtsinsel',
      es: 'Isla de Navidad',
      fr: 'Île Christmas',
      ja: 'クリスマス島',
      it: 'Isola di Natale',
      'zh-cn': '圣诞岛',
      tr: 'Christmas Adasi',
      ru: 'Остров Рождества',
      uk: 'Острів Різдва',
      pl: 'Wyspa Bożego Narodzenia'
    },
    emoji: '🇨🇽'
  },
  {
    name: 'Cocos (Keeling) Islands',
    code: 'CC',
    translations: {
      kr: '코코스 제도',
      'pt-BR': 'Ilhas Cocos',
      pt: 'Ilhas dos Cocos',
      nl: 'Cocoseilanden',
      hr: 'Kokosovi Otoci',
      fa: 'جزایر کوکوس',
      de: 'Kokosinseln',
      es: 'Islas Cocos o Islas Keeling',
      fr: 'Îles Cocos',
      ja: 'ココス（キーリング）諸島',
      it: 'Isole Cocos e Keeling',
      'zh-cn': '科科斯（基林）群岛',
      tr: 'Cocos Adalari',
      ru: 'Кокосовые (Килинг) острова',
      uk: 'Кокосові (Кілінг) острови',
      pl: 'Wyspy Kokosowe (Keelinga)'
    },
    emoji: '🇨🇨'
  },
  {
    name: 'Colombia',
    code: 'CO',
    translations: {
      kr: '콜롬비아',
      'pt-BR': 'Colômbia',
      pt: 'Colômbia',
      nl: 'Colombia',
      hr: 'Kolumbija',
      fa: 'کلمبیا',
      de: 'Kolumbien',
      es: 'Colombia',
      fr: 'Colombie',
      ja: 'コロンビア',
      it: 'Colombia',
      'zh-cn': '哥伦比亚',
      tr: 'Kolombiya',
      ru: 'Колумбия',
      uk: 'Колумбія',
      pl: 'Kolumbia'
    },
    emoji: '🇨🇴'
  },
  {
    name: 'Comoros',
    code: 'KM',
    translations: {
      kr: '코모로',
      'pt-BR': 'Comores',
      pt: 'Comores',
      nl: 'Comoren',
      hr: 'Komori',
      fa: 'کومور',
      de: 'Union der Komoren',
      es: 'Comoras',
      fr: 'Comores',
      ja: 'コモロ',
      it: 'Comore',
      'zh-cn': '科摩罗',
      tr: 'Komorlar',
      ru: 'Коморские острова',
      uk: 'Коморські острови',
      pl: 'Komory'
    },
    emoji: '🇰🇲'
  },
  {
    name: 'Congo',
    code: 'CG',
    translations: {
      kr: '콩고',
      'pt-BR': 'Congo',
      pt: 'Congo',
      nl: 'Congo [Republiek]',
      hr: 'Kongo',
      fa: 'کنگو',
      de: 'Kongo',
      es: 'Congo',
      fr: 'Congo',
      ja: 'コンゴ共和国',
      it: 'Congo',
      'zh-cn': '刚果',
      tr: 'Kongo',
      ru: 'Конго',
      uk: 'Конго',
      pl: 'Kongo'
    },
    emoji: '🇨🇬'
  },
  {
    name: 'Cook Islands',
    code: 'CK',
    translations: {
      kr: '쿡 제도',
      'pt-BR': 'Ilhas Cook',
      pt: 'Ilhas Cook',
      nl: 'Cookeilanden',
      hr: 'Cookovo Otočje',
      fa: 'جزایر کوک',
      de: 'Cookinseln',
      es: 'Islas Cook',
      fr: 'Îles Cook',
      ja: 'クック諸島',
      it: 'Isole Cook',
      'zh-cn': '库克群岛',
      tr: 'Cook Adalari',
      ru: 'Острова Кука',
      uk: 'Острови Кука',
      pl: 'Wyspy Cooka'
    },
    emoji: '🇨🇰'
  },
  {
    name: 'Costa Rica',
    code: 'CR',
    translations: {
      kr: '코스타리카',
      'pt-BR': 'Costa Rica',
      pt: 'Costa Rica',
      nl: 'Costa Rica',
      hr: 'Kostarika',
      fa: 'کاستاریکا',
      de: 'Costa Rica',
      es: 'Costa Rica',
      fr: 'Costa Rica',
      ja: 'コスタリカ',
      it: 'Costa Rica',
      'zh-cn': '哥斯达黎加',
      tr: 'Kosta Rika',
      ru: 'Коста-Рика',
      uk: 'Коста-Ріка',
      pl: 'Kostaryka'
    },
    emoji: '🇨🇷'
  },
  {
    name: "Cote D'Ivoire (Ivory Coast)",
    code: 'CI',
    translations: {
      kr: '코트디부아르',
      'pt-BR': 'Costa do Marfim',
      pt: 'Costa do Marfim',
      nl: 'Ivoorkust',
      hr: 'Obala Bjelokosti',
      fa: 'ساحل عاج',
      de: 'Elfenbeinküste',
      es: 'Costa de Marfil',
      fr: "Côte d'Ivoire",
      ja: 'コートジボワール',
      it: "Costa D'Avorio",
      'zh-cn': '科特迪瓦',
      tr: 'Kotdivuar',
      ru: "Кот-д'Ивуар (Берег Слоновой Кости)",
      uk: "Кот-д'Івуар (Берег Слонової Кістки)",
      pl: "Cote D'Ivoire (Wybrzeże Kości Słoniowej)"
    },
    emoji: '🇨🇮'
  },
  {
    name: 'Croatia',
    code: 'HR',
    translations: {
      kr: '크로아티아',
      'pt-BR': 'Croácia',
      pt: 'Croácia',
      nl: 'Kroatië',
      hr: 'Hrvatska',
      fa: 'کرواسی',
      de: 'Kroatien',
      es: 'Croacia',
      fr: 'Croatie',
      ja: 'クロアチア',
      it: 'Croazia',
      'zh-cn': '克罗地亚',
      tr: 'Hirvatistan',
      ru: 'Хорватия',
      uk: 'Хорватія',
      pl: 'Chorwacja'
    },
    emoji: '🇭🇷'
  },
  {
    name: 'Cuba',
    code: 'CU',
    translations: {
      kr: '쿠바',
      'pt-BR': 'Cuba',
      pt: 'Cuba',
      nl: 'Cuba',
      hr: 'Kuba',
      fa: 'کوبا',
      de: 'Kuba',
      es: 'Cuba',
      fr: 'Cuba',
      ja: 'キューバ',
      it: 'Cuba',
      'zh-cn': '古巴',
      tr: 'Küba',
      ru: 'Куба',
      uk: 'Куба',
      pl: 'Kuba'
    },
    emoji: '🇨🇺'
  },
  {
    name: 'Curaçao',
    code: 'CW',
    translations: {
      kr: '퀴라소',
      'pt-BR': 'Curaçao',
      pt: 'Curaçao',
      nl: 'Curaçao',
      fa: 'کوراسائو',
      de: 'Curaçao',
      fr: 'Curaçao',
      it: 'Curaçao',
      'zh-cn': '库拉索',
      tr: 'Curaçao',
      ru: 'Кюрасао',
      uk: 'Кюрасао',
      pl: 'Curaçao'
    },
    emoji: '🇨🇼'
  },
  {
    name: 'Cyprus',
    code: 'CY',
    translations: {
      kr: '키프로스',
      'pt-BR': 'Chipre',
      pt: 'Chipre',
      nl: 'Cyprus',
      hr: 'Cipar',
      fa: 'قبرس',
      de: 'Zypern',
      es: 'Chipre',
      fr: 'Chypre',
      ja: 'キプロス',
      it: 'Cipro',
      'zh-cn': '塞浦路斯',
      tr: 'Kuzey Kıbrıs Türk Cumhuriyeti',
      ru: 'Кипр',
      uk: 'Кіпр',
      pl: 'Cypr'
    },
    emoji: '🇨🇾'
  },
  {
    name: 'Czech Republic',
    code: 'CZ',
    translations: {
      kr: '체코',
      'pt-BR': 'República Tcheca',
      pt: 'República Checa',
      nl: 'Tsjechië',
      hr: 'Češka',
      fa: 'جمهوری چک',
      de: 'Tschechische Republik',
      es: 'República Checa',
      fr: 'République tchèque',
      ja: 'チェコ',
      it: 'Repubblica Ceca',
      'zh-cn': '捷克',
      tr: 'Çekya',
      ru: 'Чешская Республика',
      uk: 'Чеська Республіка',
      pl: 'Republika Czeska'
    },
    emoji: '🇨🇿'
  },
  {
    name: 'Democratic Republic of the Congo',
    code: 'CD',
    translations: {
      kr: '콩고 민주 공화국',
      'pt-BR': 'RD Congo',
      pt: 'RD Congo',
      nl: 'Congo [DRC]',
      hr: 'Kongo, Demokratska Republika',
      fa: 'جمهوری کنگو',
      de: 'Kongo (Dem. Rep.)',
      es: 'Congo (Rep. Dem.)',
      fr: 'Congo (Rép. dém.)',
      ja: 'コンゴ民主共和国',
      it: 'Congo (Rep. Dem.)',
      'zh-cn': '刚果（金）',
      tr: 'Kongo Demokratik Cumhuriyeti',
      ru: 'Демократическая Республика Конго',
      uk: 'Демократична Республіка Конго',
      pl: 'Demokratyczna Republika Konga'
    },
    emoji: '🇨🇩'
  },
  {
    name: 'Denmark',
    code: 'DK',
    translations: {
      kr: '덴마크',
      'pt-BR': 'Dinamarca',
      pt: 'Dinamarca',
      nl: 'Denemarken',
      hr: 'Danska',
      fa: 'دانمارک',
      de: 'Dänemark',
      es: 'Dinamarca',
      fr: 'Danemark',
      ja: 'デンマーク',
      it: 'Danimarca',
      'zh-cn': '丹麦',
      tr: 'Danimarka',
      ru: 'Дания',
      uk: 'Данія',
      pl: 'Dania'
    },
    emoji: '🇩🇰'
  },
  {
    name: 'Djibouti',
    code: 'DJ',
    translations: {
      kr: '지부티',
      'pt-BR': 'Djibuti',
      pt: 'Djibuti',
      nl: 'Djibouti',
      hr: 'Džibuti',
      fa: 'جیبوتی',
      de: 'Dschibuti',
      es: 'Yibuti',
      fr: 'Djibouti',
      ja: 'ジブチ',
      it: 'Gibuti',
      'zh-cn': '吉布提',
      tr: 'Cibuti',
      ru: 'Джибути',
      uk: 'Джибуті',
      pl: 'Dżibuti'
    },
    emoji: '🇩🇯'
  },
  {
    name: 'Dominica',
    code: 'DM',
    translations: {
      kr: '도미니카 연방',
      'pt-BR': 'Dominica',
      pt: 'Dominica',
      nl: 'Dominica',
      hr: 'Dominika',
      fa: 'دومینیکا',
      de: 'Dominica',
      es: 'Dominica',
      fr: 'Dominique',
      ja: 'ドミニカ国',
      it: 'Dominica',
      'zh-cn': '多米尼加',
      tr: 'Dominika',
      ru: 'Доминика',
      uk: 'Домініка',
      pl: 'Dominika'
    },
    emoji: '🇩🇲'
  },
  {
    name: 'Dominican Republic',
    code: 'DO',
    translations: {
      kr: '도미니카 공화국',
      'pt-BR': 'República Dominicana',
      pt: 'República Dominicana',
      nl: 'Dominicaanse Republiek',
      hr: 'Dominikanska Republika',
      fa: 'جمهوری دومینیکن',
      de: 'Dominikanische Republik',
      es: 'República Dominicana',
      fr: 'République dominicaine',
      ja: 'ドミニカ共和国',
      it: 'Repubblica Dominicana',
      'zh-cn': '多明尼加共和国',
      tr: 'Dominik Cumhuriyeti',
      ru: 'Доминиканская Республика',
      uk: 'Домініканська Республіка',
      pl: 'Republika Dominikańska'
    },
    emoji: '🇩🇴'
  },
  {
    name: 'Ecuador',
    code: 'EC',
    translations: {
      kr: '에콰도르',
      'pt-BR': 'Equador',
      pt: 'Equador',
      nl: 'Ecuador',
      hr: 'Ekvador',
      fa: 'اکوادور',
      de: 'Ecuador',
      es: 'Ecuador',
      fr: 'Équateur',
      ja: 'エクアドル',
      it: 'Ecuador',
      'zh-cn': '厄瓜多尔',
      tr: 'Ekvator',
      ru: 'Эквадор',
      uk: 'Еквадор',
      pl: 'Ekwador'
    },
    emoji: '🇪🇨'
  },
  {
    name: 'Egypt',
    code: 'EG',
    translations: {
      kr: '이집트',
      'pt-BR': 'Egito',
      pt: 'Egipto',
      nl: 'Egypte',
      hr: 'Egipat',
      fa: 'مصر',
      de: 'Ägypten',
      es: 'Egipto',
      fr: 'Égypte',
      ja: 'エジプト',
      it: 'Egitto',
      'zh-cn': '埃及',
      tr: 'Mısır',
      ru: 'Египет',
      uk: 'Єгипет',
      pl: 'Egipt'
    },
    emoji: '🇪🇬'
  },
  {
    name: 'El Salvador',
    code: 'SV',
    translations: {
      kr: '엘살바도르',
      'pt-BR': 'El Salvador',
      pt: 'El Salvador',
      nl: 'El Salvador',
      hr: 'Salvador',
      fa: 'السالوادور',
      de: 'El Salvador',
      es: 'El Salvador',
      fr: 'Salvador',
      ja: 'エルサルバドル',
      it: 'El Salvador',
      'zh-cn': '萨尔瓦多',
      tr: 'El Salvador',
      ru: 'Сальвадор',
      uk: 'Сальвадор',
      pl: 'Salwador'
    },
    emoji: '🇸🇻'
  },
  {
    name: 'Equatorial Guinea',
    code: 'GQ',
    translations: {
      kr: '적도 기니',
      'pt-BR': 'Guiné Equatorial',
      pt: 'Guiné Equatorial',
      nl: 'Equatoriaal-Guinea',
      hr: 'Ekvatorijalna Gvineja',
      fa: 'گینه استوایی',
      de: 'Äquatorial-Guinea',
      es: 'Guinea Ecuatorial',
      fr: 'Guinée-Équatoriale',
      ja: '赤道ギニア',
      it: 'Guinea Equatoriale',
      'zh-cn': '赤道几内亚',
      tr: 'Ekvator Ginesi',
      ru: 'Экваториальная Гвинея',
      uk: 'Екваторіальна Гвінея',
      pl: 'Gwinea Równikowa'
    },
    emoji: '🇬🇶'
  },
  {
    name: 'Eritrea',
    code: 'ER',
    translations: {
      kr: '에리트레아',
      'pt-BR': 'Eritreia',
      pt: 'Eritreia',
      nl: 'Eritrea',
      hr: 'Eritreja',
      fa: 'اریتره',
      de: 'Eritrea',
      es: 'Eritrea',
      fr: 'Érythrée',
      ja: 'エリトリア',
      it: 'Eritrea',
      'zh-cn': '厄立特里亚',
      tr: 'Eritre',
      ru: 'Эритрея',
      uk: 'Еритрея',
      pl: 'Erytrea'
    },
    emoji: '🇪🇷'
  },
  {
    name: 'Estonia',
    code: 'EE',
    translations: {
      kr: '에스토니아',
      'pt-BR': 'Estônia',
      pt: 'Estónia',
      nl: 'Estland',
      hr: 'Estonija',
      fa: 'استونی',
      de: 'Estland',
      es: 'Estonia',
      fr: 'Estonie',
      ja: 'エストニア',
      it: 'Estonia',
      'zh-cn': '爱沙尼亚',
      tr: 'Estonya',
      ru: 'Эстония',
      uk: 'Естонія',
      pl: 'Estonia'
    },
    emoji: '🇪🇪'
  },
  {
    name: 'Eswatini',
    code: 'SZ',
    translations: {
      kr: '에스와티니',
      'pt-BR': 'Suazilândia',
      pt: 'Suazilândia',
      nl: 'Swaziland',
      hr: 'Svazi',
      fa: 'سوازیلند',
      de: 'Swasiland',
      es: 'Suazilandia',
      fr: 'Swaziland',
      ja: 'スワジランド',
      it: 'Swaziland',
      'zh-cn': '斯威士兰',
      tr: 'Esvatini',
      ru: 'Эсватини',
      uk: 'Есватіні',
      pl: 'Eswatini'
    },
    emoji: '🇸🇿'
  },
  {
    name: 'Ethiopia',
    code: 'ET',
    translations: {
      kr: '에티오피아',
      'pt-BR': 'Etiópia',
      pt: 'Etiópia',
      nl: 'Ethiopië',
      hr: 'Etiopija',
      fa: 'اتیوپی',
      de: 'Äthiopien',
      es: 'Etiopía',
      fr: 'Éthiopie',
      ja: 'エチオピア',
      it: 'Etiopia',
      'zh-cn': '埃塞俄比亚',
      tr: 'Etiyopya',
      ru: 'Эфиопия',
      uk: 'Ефіопія',
      pl: 'Etiopia'
    },
    emoji: '🇪🇹'
  },
  {
    name: 'Falkland Islands',
    code: 'FK',
    translations: {
      kr: '포클랜드 제도',
      'pt-BR': 'Ilhas Malvinas',
      pt: 'Ilhas Falkland',
      nl: 'Falklandeilanden [Islas Malvinas]',
      hr: 'Falklandski Otoci',
      fa: 'جزایر فالکلند',
      de: 'Falklandinseln',
      es: 'Islas Malvinas',
      fr: 'Îles Malouines',
      ja: 'フォークランド（マルビナス）諸島',
      it: 'Isole Falkland o Isole Malvine',
      'zh-cn': '福克兰群岛',
      tr: 'Falkland Adalari',
      ru: 'Фолклендские острова',
      uk: 'Фолклендські острови',
      pl: 'Falklandy'
    },
    emoji: '🇫🇰'
  },
  {
    name: 'Faroe Islands',
    code: 'FO',
    translations: {
      kr: '페로 제도',
      'pt-BR': 'Ilhas Faroé',
      pt: 'Ilhas Faroé',
      nl: 'Faeröer',
      hr: 'Farski Otoci',
      fa: 'جزایر فارو',
      de: 'Färöer-Inseln',
      es: 'Islas Faroe',
      fr: 'Îles Féroé',
      ja: 'フェロー諸島',
      it: 'Isole Far Oer',
      'zh-cn': '法罗群岛',
      tr: 'Faroe Adalari',
      ru: 'Фарерские острова',
      uk: 'Фарерські острови',
      pl: 'Wyspy Owcze'
    },
    emoji: '🇫🇴'
  },
  {
    name: 'Fiji Islands',
    code: 'FJ',
    translations: {
      kr: '피지',
      'pt-BR': 'Fiji',
      pt: 'Fiji',
      nl: 'Fiji',
      hr: 'Fiđi',
      fa: 'فیجی',
      de: 'Fidschi',
      es: 'Fiyi',
      fr: 'Fidji',
      ja: 'フィジー',
      it: 'Figi',
      'zh-cn': '斐济',
      tr: 'Fiji',
      ru: 'Острова Фиджи',
      uk: 'Острови Фіджі',
      pl: 'Wyspy Fidżi'
    },
    emoji: '🇫🇯'
  },
  {
    name: 'Finland',
    code: 'FI',
    translations: {
      kr: '핀란드',
      'pt-BR': 'Finlândia',
      pt: 'Finlândia',
      nl: 'Finland',
      hr: 'Finska',
      fa: 'فنلاند',
      de: 'Finnland',
      es: 'Finlandia',
      fr: 'Finlande',
      ja: 'フィンランド',
      it: 'Finlandia',
      'zh-cn': '芬兰',
      tr: 'Finlandiya',
      ru: 'Финляндия',
      uk: 'Фінляндія',
      pl: 'Finlandia'
    },
    emoji: '🇫🇮'
  },
  {
    name: 'France',
    code: 'FR',
    translations: {
      kr: '프랑스',
      'pt-BR': 'França',
      pt: 'França',
      nl: 'Frankrijk',
      hr: 'Francuska',
      fa: 'فرانسه',
      de: 'Frankreich',
      es: 'Francia',
      fr: 'France',
      ja: 'フランス',
      it: 'Francia',
      'zh-cn': '法国',
      tr: 'Fransa',
      ru: 'Франция',
      uk: 'Франція',
      pl: 'Francja'
    },
    emoji: '🇫🇷'
  },
  {
    name: 'French Guiana',
    code: 'GF',
    translations: {
      kr: '프랑스령 기아나',
      'pt-BR': 'Guiana Francesa',
      pt: 'Guiana Francesa',
      nl: 'Frans-Guyana',
      hr: 'Francuska Gvajana',
      fa: 'گویان فرانسه',
      de: 'Französisch Guyana',
      es: 'Guayana Francesa',
      fr: 'Guayane',
      ja: 'フランス領ギアナ',
      it: 'Guyana francese',
      'zh-cn': '法属圭亚那',
      tr: 'Fransiz Guyanasi',
      ru: 'Французская Гвиана',
      uk: 'Французька Гвіана',
      pl: 'Gujana Francuska'
    },
    emoji: '🇬🇫'
  },
  {
    name: 'French Polynesia',
    code: 'PF',
    translations: {
      kr: '프랑스령 폴리네시아',
      'pt-BR': 'Polinésia Francesa',
      pt: 'Polinésia Francesa',
      nl: 'Frans-Polynesië',
      hr: 'Francuska Polinezija',
      fa: 'پلی‌نزی فرانسه',
      de: 'Französisch-Polynesien',
      es: 'Polinesia Francesa',
      fr: 'Polynésie française',
      ja: 'フランス領ポリネシア',
      it: 'Polinesia Francese',
      'zh-cn': '法属波利尼西亚',
      tr: 'Fransiz Polinezyasi',
      ru: 'Французская Полинезия',
      uk: 'Французька Полінезія',
      pl: 'Polinezja Francuska'
    },
    emoji: '🇵🇫'
  },
  {
    name: 'French Southern Territories',
    code: 'TF',
    translations: {
      kr: '프랑스령 남방 및 남극',
      'pt-BR': 'Terras Austrais e Antárticas Francesas',
      pt: 'Terras Austrais e Antárticas Francesas',
      nl: 'Franse Gebieden in de zuidelijke Indische Oceaan',
      hr: 'Francuski južni i antarktički teritoriji',
      fa: 'سرزمین‌های جنوبی و جنوبگانی فرانسه',
      de: 'Französische Süd- und Antarktisgebiete',
      es: 'Tierras Australes y Antárticas Francesas',
      fr: 'Terres australes et antarctiques françaises',
      ja: 'フランス領南方・南極地域',
      it: 'Territori Francesi del Sud',
      'zh-cn': '法属南部领地',
      tr: 'Fransiz Güney Topraklari',
      ru: 'Французские южные территории',
      uk: 'Французькі південні території',
      pl: 'Francuskie terytoria południowe'
    },
    emoji: '🇹🇫'
  },
  {
    name: 'Gabon',
    code: 'GA',
    translations: {
      kr: '가봉',
      'pt-BR': 'Gabão',
      pt: 'Gabão',
      nl: 'Gabon',
      hr: 'Gabon',
      fa: 'گابن',
      de: 'Gabun',
      es: 'Gabón',
      fr: 'Gabon',
      ja: 'ガボン',
      it: 'Gabon',
      'zh-cn': '加蓬',
      tr: 'Gabon',
      ru: 'Габон',
      uk: 'Габон',
      pl: 'Gabon'
    },
    emoji: '🇬🇦'
  },
  {
    name: 'Georgia',
    code: 'GE',
    translations: {
      kr: '조지아',
      'pt-BR': 'Geórgia',
      pt: 'Geórgia',
      nl: 'Georgië',
      hr: 'Gruzija',
      fa: 'گرجستان',
      de: 'Georgien',
      es: 'Georgia',
      fr: 'Géorgie',
      ja: 'グルジア',
      it: 'Georgia',
      'zh-cn': '格鲁吉亚',
      tr: 'Gürcistan',
      ru: 'Джорджия',
      uk: 'Грузія',
      pl: 'Gruzja'
    },
    emoji: '🇬🇪'
  },
  {
    name: 'Germany',
    code: 'DE',
    translations: {
      kr: '독일',
      'pt-BR': 'Alemanha',
      pt: 'Alemanha',
      nl: 'Duitsland',
      hr: 'Njemačka',
      fa: 'آلمان',
      de: 'Deutschland',
      es: 'Alemania',
      fr: 'Allemagne',
      ja: 'ドイツ',
      it: 'Germania',
      'zh-cn': '德国',
      tr: 'Almanya',
      ru: 'Германия',
      uk: 'Німеччина',
      pl: 'Niemcy'
    },
    emoji: '🇩🇪'
  },
  {
    name: 'Ghana',
    code: 'GH',
    translations: {
      kr: '가나',
      'pt-BR': 'Gana',
      pt: 'Gana',
      nl: 'Ghana',
      hr: 'Gana',
      fa: 'غنا',
      de: 'Ghana',
      es: 'Ghana',
      fr: 'Ghana',
      ja: 'ガーナ',
      it: 'Ghana',
      'zh-cn': '加纳',
      tr: 'Gana',
      ru: 'Гана',
      uk: 'Гана',
      pl: 'Ghana'
    },
    emoji: '🇬🇭'
  },
  {
    name: 'Gibraltar',
    code: 'GI',
    translations: {
      kr: '지브롤터',
      'pt-BR': 'Gibraltar',
      pt: 'Gibraltar',
      nl: 'Gibraltar',
      hr: 'Gibraltar',
      fa: 'جبل‌طارق',
      de: 'Gibraltar',
      es: 'Gibraltar',
      fr: 'Gibraltar',
      ja: 'ジブラルタル',
      it: 'Gibilterra',
      'zh-cn': '直布罗陀',
      tr: 'Cebelitarik',
      ru: 'Гибралтар',
      uk: 'Гібралтар',
      pl: 'Gibraltar'
    },
    emoji: '🇬🇮'
  },
  {
    name: 'Greece',
    code: 'GR',
    translations: {
      kr: '그리스',
      'pt-BR': 'Grécia',
      pt: 'Grécia',
      nl: 'Griekenland',
      hr: 'Grčka',
      fa: 'یونان',
      de: 'Griechenland',
      es: 'Grecia',
      fr: 'Grèce',
      ja: 'ギリシャ',
      it: 'Grecia',
      'zh-cn': '希腊',
      tr: 'Yunanistan',
      ru: 'Греция',
      uk: 'Греція',
      pl: 'Grecja'
    },
    emoji: '🇬🇷'
  },
  {
    name: 'Greenland',
    code: 'GL',
    translations: {
      kr: '그린란드',
      'pt-BR': 'Groelândia',
      pt: 'Gronelândia',
      nl: 'Groenland',
      hr: 'Grenland',
      fa: 'گرینلند',
      de: 'Grönland',
      es: 'Groenlandia',
      fr: 'Groenland',
      ja: 'グリーンランド',
      it: 'Groenlandia',
      'zh-cn': '格陵兰岛',
      tr: 'Grönland',
      ru: 'Гренландия',
      uk: 'Гренландія',
      pl: 'Grenlandia'
    },
    emoji: '🇬🇱'
  },
  {
    name: 'Grenada',
    code: 'GD',
    translations: {
      kr: '그레나다',
      'pt-BR': 'Granada',
      pt: 'Granada',
      nl: 'Grenada',
      hr: 'Grenada',
      fa: 'گرنادا',
      de: 'Grenada',
      es: 'Grenada',
      fr: 'Grenade',
      ja: 'グレナダ',
      it: 'Grenada',
      'zh-cn': '格林纳达',
      tr: 'Grenada',
      ru: 'Гренада',
      uk: 'Гренада',
      pl: 'Grenada'
    },
    emoji: '🇬🇩'
  },
  {
    name: 'Guadeloupe',
    code: 'GP',
    translations: {
      kr: '과들루프',
      'pt-BR': 'Guadalupe',
      pt: 'Guadalupe',
      nl: 'Guadeloupe',
      hr: 'Gvadalupa',
      fa: 'جزیره گوادلوپ',
      de: 'Guadeloupe',
      es: 'Guadalupe',
      fr: 'Guadeloupe',
      ja: 'グアドループ',
      it: 'Guadeloupa',
      'zh-cn': '瓜德罗普岛',
      tr: 'Guadeloupe',
      ru: 'Гваделупа',
      uk: 'Гваделупа',
      pl: 'Gwadelupa'
    },
    emoji: '🇬🇵'
  },
  {
    name: 'Guam',
    code: 'GU',
    translations: {
      kr: '괌',
      'pt-BR': 'Guam',
      pt: 'Guame',
      nl: 'Guam',
      hr: 'Guam',
      fa: 'گوام',
      de: 'Guam',
      es: 'Guam',
      fr: 'Guam',
      ja: 'グアム',
      it: 'Guam',
      'zh-cn': '关岛',
      tr: 'Guam',
      ru: 'Гуам',
      uk: 'Гуам',
      pl: 'Guam'
    },
    emoji: '🇬🇺'
  },
  {
    name: 'Guatemala',
    code: 'GT',
    translations: {
      kr: '과테말라',
      'pt-BR': 'Guatemala',
      pt: 'Guatemala',
      nl: 'Guatemala',
      hr: 'Gvatemala',
      fa: 'گواتمالا',
      de: 'Guatemala',
      es: 'Guatemala',
      fr: 'Guatemala',
      ja: 'グアテマラ',
      it: 'Guatemala',
      'zh-cn': '危地马拉',
      tr: 'Guatemala',
      ru: 'Гватемала',
      uk: 'Гватемала',
      pl: 'Gwatemala'
    },
    emoji: '🇬🇹'
  },
  {
    name: 'Guernsey and Alderney',
    code: 'GG',
    translations: {
      kr: '건지, 올더니',
      'pt-BR': 'Guernsey',
      pt: 'Guernsey',
      nl: 'Guernsey',
      hr: 'Guernsey',
      fa: 'گرنزی',
      de: 'Guernsey',
      es: 'Guernsey',
      fr: 'Guernesey',
      ja: 'ガーンジー',
      it: 'Guernsey',
      'zh-cn': '根西岛',
      tr: 'Alderney',
      ru: 'Гернси и Олдерни',
      uk: 'Гернсі та Олдерні',
      pl: 'Guernsey i Alderney'
    },
    emoji: '🇬🇬'
  },
  {
    name: 'Guinea',
    code: 'GN',
    translations: {
      kr: '기니',
      'pt-BR': 'Guiné',
      pt: 'Guiné',
      nl: 'Guinee',
      hr: 'Gvineja',
      fa: 'گینه',
      de: 'Guinea',
      es: 'Guinea',
      fr: 'Guinée',
      ja: 'ギニア',
      it: 'Guinea',
      'zh-cn': '几内亚',
      tr: 'Gine',
      ru: 'Гвинея',
      uk: 'Гвінея',
      pl: 'Gwinea'
    },
    emoji: '🇬🇳'
  },
  {
    name: 'Guinea-Bissau',
    code: 'GW',
    translations: {
      kr: '기니비사우',
      'pt-BR': 'Guiné-Bissau',
      pt: 'Guiné-Bissau',
      nl: 'Guinee-Bissau',
      hr: 'Gvineja Bisau',
      fa: 'گینه بیسائو',
      de: 'Guinea-Bissau',
      es: 'Guinea-Bisáu',
      fr: 'Guinée-Bissau',
      ja: 'ギニアビサウ',
      it: 'Guinea-Bissau',
      'zh-cn': '几内亚比绍',
      tr: 'Gine-bissau',
      ru: 'Гвинея-Бисау',
      uk: 'Гвінея-Бісау',
      pl: 'Gwinea Bissau'
    },
    emoji: '🇬🇼'
  },
  {
    name: 'Guyana',
    code: 'GY',
    translations: {
      kr: '가이아나',
      'pt-BR': 'Guiana',
      pt: 'Guiana',
      nl: 'Guyana',
      hr: 'Gvajana',
      fa: 'گویان',
      de: 'Guyana',
      es: 'Guyana',
      fr: 'Guyane',
      ja: 'ガイアナ',
      it: 'Guyana',
      'zh-cn': '圭亚那',
      tr: 'Guyana',
      ru: 'Гайана',
      uk: 'Гайана',
      pl: 'Gujana'
    },
    emoji: '🇬🇾'
  },
  {
    name: 'Haiti',
    code: 'HT',
    translations: {
      kr: '아이티',
      'pt-BR': 'Haiti',
      pt: 'Haiti',
      nl: 'Haïti',
      hr: 'Haiti',
      fa: 'هائیتی',
      de: 'Haiti',
      es: 'Haiti',
      fr: 'Haïti',
      ja: 'ハイチ',
      it: 'Haiti',
      'zh-cn': '海地',
      tr: 'Haiti',
      ru: 'Гаити',
      uk: 'Гаїті',
      pl: 'Haiti'
    },
    emoji: '🇭🇹'
  },
  {
    name: 'Heard Island and McDonald Islands',
    code: 'HM',
    translations: {
      kr: '허드 맥도날드 제도',
      'pt-BR': 'Ilha Heard e Ilhas McDonald',
      pt: 'Ilha Heard e Ilhas McDonald',
      nl: 'Heard- en McDonaldeilanden',
      hr: 'Otok Heard i otočje McDonald',
      fa: 'جزیره هرد و جزایر مک‌دونالد',
      de: 'Heard und die McDonaldinseln',
      es: 'Islas Heard y McDonald',
      fr: 'Îles Heard-et-MacDonald',
      ja: 'ハード島とマクドナルド諸島',
      it: 'Isole Heard e McDonald',
      'zh-cn': '赫德·唐纳岛及麦唐纳岛',
      tr: 'Heard Adasi Ve Mcdonald Adalari',
      ru: 'Остров Херд и острова Макдональд',
      uk: 'Острів Херд та острови Макдональд',
      pl: 'Wyspa Heard i Wyspy McDonalda'
    },
    emoji: '🇭🇲'
  },
  {
    name: 'Honduras',
    code: 'HN',
    translations: {
      kr: '온두라스',
      'pt-BR': 'Honduras',
      pt: 'Honduras',
      nl: 'Honduras',
      hr: 'Honduras',
      fa: 'هندوراس',
      de: 'Honduras',
      es: 'Honduras',
      fr: 'Honduras',
      ja: 'ホンジュラス',
      it: 'Honduras',
      'zh-cn': '洪都拉斯',
      tr: 'Honduras',
      ru: 'Гондурас',
      uk: 'Гондурас',
      pl: 'Honduras'
    },
    emoji: '🇭🇳'
  },
  {
    name: 'Hong Kong S.A.R.',
    code: 'HK',
    translations: {
      kr: '홍콩',
      'pt-BR': 'Hong Kong',
      pt: 'Hong Kong',
      nl: 'Hongkong',
      hr: 'Hong Kong',
      fa: 'هنگ‌کنگ',
      de: 'Hong Kong',
      es: 'Hong Kong',
      fr: 'Hong Kong',
      ja: '香港',
      it: 'Hong Kong',
      'zh-cn': '中国香港',
      tr: 'Hong Kong',
      ru: 'Гонконг С.А.Р.',
      uk: 'Гонконг САР.',
      pl: 'Hongkong S.A.R.'
    },
    emoji: '🇭🇰'
  },
  {
    name: 'Hungary',
    code: 'HU',
    translations: {
      kr: '헝가리',
      'pt-BR': 'Hungria',
      pt: 'Hungria',
      nl: 'Hongarije',
      hr: 'Mađarska',
      fa: 'مجارستان',
      de: 'Ungarn',
      es: 'Hungría',
      fr: 'Hongrie',
      ja: 'ハンガリー',
      it: 'Ungheria',
      'zh-cn': '匈牙利',
      tr: 'Macaristan',
      ru: 'Венгрия',
      uk: 'Угорщина',
      pl: 'Węgry'
    },
    emoji: '🇭🇺'
  },
  {
    name: 'Iceland',
    code: 'IS',
    translations: {
      kr: '아이슬란드',
      'pt-BR': 'Islândia',
      pt: 'Islândia',
      nl: 'IJsland',
      hr: 'Island',
      fa: 'ایسلند',
      de: 'Island',
      es: 'Islandia',
      fr: 'Islande',
      ja: 'アイスランド',
      it: 'Islanda',
      'zh-cn': '冰岛',
      tr: 'İzlanda',
      ru: 'Исландия',
      uk: 'Ісландія',
      pl: 'Islandia'
    },
    emoji: '🇮🇸'
  },
  {
    name: 'India',
    code: 'IN',
    translations: {
      kr: '인도',
      'pt-BR': 'Índia',
      pt: 'Índia',
      nl: 'India',
      hr: 'Indija',
      fa: 'هند',
      de: 'Indien',
      es: 'India',
      fr: 'Inde',
      ja: 'インド',
      it: 'India',
      'zh-cn': '印度',
      tr: 'Hindistan',
      ru: 'Индия',
      uk: 'Індія',
      pl: 'Indie'
    },
    emoji: '🇮🇳'
  },
  {
    name: 'Indonesia',
    code: 'ID',
    translations: {
      kr: '인도네시아',
      'pt-BR': 'Indonésia',
      pt: 'Indonésia',
      nl: 'Indonesië',
      hr: 'Indonezija',
      fa: 'اندونزی',
      de: 'Indonesien',
      es: 'Indonesia',
      fr: 'Indonésie',
      ja: 'インドネシア',
      it: 'Indonesia',
      'zh-cn': '印度尼西亚',
      tr: 'Endonezya',
      ru: 'Индонезия',
      uk: 'Індонезія',
      pl: 'Indonezja'
    },
    emoji: '🇮🇩'
  },
  {
    name: 'Iran',
    code: 'IR',
    translations: {
      kr: '이란',
      'pt-BR': 'Irã',
      pt: 'Irão',
      nl: 'Iran',
      hr: 'Iran',
      fa: 'ایران',
      de: 'Iran',
      es: 'Iran',
      fr: 'Iran',
      ja: 'イラン・イスラム共和国',
      'zh-cn': '伊朗',
      tr: 'İran',
      ru: 'Иран',
      uk: 'Іран',
      pl: 'Iran'
    },
    emoji: '🇮🇷'
  },
  {
    name: 'Iraq',
    code: 'IQ',
    translations: {
      kr: '이라크',
      'pt-BR': 'Iraque',
      pt: 'Iraque',
      nl: 'Irak',
      hr: 'Irak',
      fa: 'عراق',
      de: 'Irak',
      es: 'Irak',
      fr: 'Irak',
      ja: 'イラク',
      it: 'Iraq',
      'zh-cn': '伊拉克',
      tr: 'Irak',
      ru: 'Ирак',
      uk: 'Ірак',
      pl: 'Irak'
    },
    emoji: '🇮🇶'
  },
  {
    name: 'Ireland',
    code: 'IE',
    translations: {
      kr: '아일랜드',
      'pt-BR': 'Irlanda',
      pt: 'Irlanda',
      nl: 'Ierland',
      hr: 'Irska',
      fa: 'ایرلند',
      de: 'Irland',
      es: 'Irlanda',
      fr: 'Irlande',
      ja: 'アイルランド',
      it: 'Irlanda',
      'zh-cn': '爱尔兰',
      tr: 'İrlanda',
      ru: 'Ирландия',
      uk: 'Ірландія',
      pl: 'Irlandia'
    },
    emoji: '🇮🇪'
  },
  {
    name: 'Israel',
    code: 'IL',
    translations: {
      kr: '이스라엘',
      'pt-BR': 'Israel',
      pt: 'Israel',
      nl: 'Israël',
      hr: 'Izrael',
      fa: 'اسرائیل',
      de: 'Israel',
      es: 'Israel',
      fr: 'Israël',
      ja: 'イスラエル',
      it: 'Israele',
      'zh-cn': '以色列',
      tr: 'İsrail',
      ru: 'Израиль',
      uk: 'Ізраїль',
      pl: 'Izrael'
    },
    emoji: '🇮🇱'
  },
  {
    name: 'Italy',
    code: 'IT',
    translations: {
      kr: '이탈리아',
      'pt-BR': 'Itália',
      pt: 'Itália',
      nl: 'Italië',
      hr: 'Italija',
      fa: 'ایتالیا',
      de: 'Italien',
      es: 'Italia',
      fr: 'Italie',
      ja: 'イタリア',
      it: 'Italia',
      'zh-cn': '意大利',
      tr: 'İtalya',
      ru: 'Италия',
      uk: 'Італія',
      pl: 'Włochy'
    },
    emoji: '🇮🇹'
  },
  {
    name: 'Jamaica',
    code: 'JM',
    translations: {
      kr: '자메이카',
      'pt-BR': 'Jamaica',
      pt: 'Jamaica',
      nl: 'Jamaica',
      hr: 'Jamajka',
      fa: 'جامائیکا',
      de: 'Jamaika',
      es: 'Jamaica',
      fr: 'Jamaïque',
      ja: 'ジャマイカ',
      it: 'Giamaica',
      'zh-cn': '牙买加',
      tr: 'Jamaika',
      ru: 'Ямайка',
      uk: 'Ямайка',
      pl: 'Jamajka'
    },
    emoji: '🇯🇲'
  },
  {
    name: 'Japan',
    code: 'JP',
    translations: {
      kr: '일본',
      'pt-BR': 'Japão',
      pt: 'Japão',
      nl: 'Japan',
      hr: 'Japan',
      fa: 'ژاپن',
      de: 'Japan',
      es: 'Japón',
      fr: 'Japon',
      ja: '日本',
      it: 'Giappone',
      'zh-cn': '日本',
      tr: 'Japonya',
      ru: 'Япония',
      uk: 'Японія',
      pl: 'Japonia'
    },
    emoji: '🇯🇵'
  },
  {
    name: 'Jersey',
    code: 'JE',
    translations: {
      kr: '저지 섬',
      'pt-BR': 'Jersey',
      pt: 'Jersey',
      nl: 'Jersey',
      hr: 'Jersey',
      fa: 'جرزی',
      de: 'Jersey',
      es: 'Jersey',
      fr: 'Jersey',
      ja: 'ジャージー',
      it: 'Isola di Jersey',
      'zh-cn': '泽西岛',
      tr: 'Jersey',
      ru: 'Джерси',
      uk: 'Джерсі',
      pl: 'Jersey'
    },
    emoji: '🇯🇪'
  },
  {
    name: 'Jordan',
    code: 'JO',
    translations: {
      kr: '요르단',
      'pt-BR': 'Jordânia',
      pt: 'Jordânia',
      nl: 'Jordanië',
      hr: 'Jordan',
      fa: 'اردن',
      de: 'Jordanien',
      es: 'Jordania',
      fr: 'Jordanie',
      ja: 'ヨルダン',
      it: 'Giordania',
      'zh-cn': '约旦',
      tr: 'Ürdün',
      ru: 'Джордан',
      uk: 'Йорданія',
      pl: 'Jordan'
    },
    emoji: '🇯🇴'
  },
  {
    name: 'Kazakhstan',
    code: 'KZ',
    translations: {
      kr: '카자흐스탄',
      'pt-BR': 'Cazaquistão',
      pt: 'Cazaquistão',
      nl: 'Kazachstan',
      hr: 'Kazahstan',
      fa: 'قزاقستان',
      de: 'Kasachstan',
      es: 'Kazajistán',
      fr: 'Kazakhstan',
      ja: 'カザフスタン',
      it: 'Kazakistan',
      'zh-cn': '哈萨克斯坦',
      tr: 'Kazakistan',
      ru: 'Казахстан',
      uk: 'Казахстан',
      pl: 'Kazachstan'
    },
    emoji: '🇰🇿'
  },
  {
    name: 'Kenya',
    code: 'KE',
    translations: {
      kr: '케냐',
      'pt-BR': 'Quênia',
      pt: 'Quénia',
      nl: 'Kenia',
      hr: 'Kenija',
      fa: 'کنیا',
      de: 'Kenia',
      es: 'Kenia',
      fr: 'Kenya',
      ja: 'ケニア',
      it: 'Kenya',
      'zh-cn': '肯尼亚',
      tr: 'Kenya',
      ru: 'Кения',
      uk: 'Кенія',
      pl: 'Kenia'
    },
    emoji: '🇰🇪'
  },
  {
    name: 'Kiribati',
    code: 'KI',
    translations: {
      kr: '키리바시',
      'pt-BR': 'Kiribati',
      pt: 'Quiribáti',
      nl: 'Kiribati',
      hr: 'Kiribati',
      fa: 'کیریباتی',
      de: 'Kiribati',
      es: 'Kiribati',
      fr: 'Kiribati',
      ja: 'キリバス',
      it: 'Kiribati',
      'zh-cn': '基里巴斯',
      tr: 'Kiribati',
      ru: 'Кирибати',
      uk: 'Кірібаті',
      pl: 'Kiribati'
    },
    emoji: '🇰🇮'
  },
  {
    name: 'Kosovo',
    code: 'XK',
    translations: {
      kr: '코소보',
      'zh-cn': '科索沃',
      tr: 'Kosova',
      ru: 'Косово',
      uk: 'Косово',
      pl: 'Kosowo'
    },
    emoji: '🇽🇰'
  },
  {
    name: 'Kuwait',
    code: 'KW',
    translations: {
      kr: '쿠웨이트',
      'pt-BR': 'Kuwait',
      pt: 'Kuwait',
      nl: 'Koeweit',
      hr: 'Kuvajt',
      fa: 'کویت',
      de: 'Kuwait',
      es: 'Kuwait',
      fr: 'Koweït',
      ja: 'クウェート',
      it: 'Kuwait',
      'zh-cn': '科威特',
      tr: 'Kuveyt',
      ru: 'Кувейт',
      uk: 'Кувейт',
      pl: 'Kuwejt'
    },
    emoji: '🇰🇼'
  },
  {
    name: 'Kyrgyzstan',
    code: 'KG',
    translations: {
      kr: '키르기스스탄',
      'pt-BR': 'Quirguistão',
      pt: 'Quirguizistão',
      nl: 'Kirgizië',
      hr: 'Kirgistan',
      fa: 'قرقیزستان',
      de: 'Kirgisistan',
      es: 'Kirguizistán',
      fr: 'Kirghizistan',
      ja: 'キルギス',
      it: 'Kirghizistan',
      'zh-cn': '吉尔吉斯斯坦',
      tr: 'Kirgizistan',
      ru: 'Кыргызстан',
      uk: 'Киргизстан',
      pl: 'Kirgistan'
    },
    emoji: '🇰🇬'
  },
  {
    name: 'Laos',
    code: 'LA',
    translations: {
      kr: '라오스',
      'pt-BR': 'Laos',
      pt: 'Laos',
      nl: 'Laos',
      hr: 'Laos',
      fa: 'لائوس',
      de: 'Laos',
      es: 'Laos',
      fr: 'Laos',
      ja: 'ラオス人民民主共和国',
      it: 'Laos',
      'zh-cn': '寮人民民主共和国',
      tr: 'Laos',
      ru: 'Лаос',
      uk: 'Лаос',
      pl: 'Laos'
    },
    emoji: '🇱🇦'
  },
  {
    name: 'Latvia',
    code: 'LV',
    translations: {
      kr: '라트비아',
      'pt-BR': 'Letônia',
      pt: 'Letónia',
      nl: 'Letland',
      hr: 'Latvija',
      fa: 'لتونی',
      de: 'Lettland',
      es: 'Letonia',
      fr: 'Lettonie',
      ja: 'ラトビア',
      it: 'Lettonia',
      'zh-cn': '拉脱维亚',
      tr: 'Letonya',
      ru: 'Латвия',
      uk: 'Латвія',
      pl: 'Łotwa'
    },
    emoji: '🇱🇻'
  },
  {
    name: 'Lebanon',
    code: 'LB',
    translations: {
      kr: '레바논',
      'pt-BR': 'Líbano',
      pt: 'Líbano',
      nl: 'Libanon',
      hr: 'Libanon',
      fa: 'لبنان',
      de: 'Libanon',
      es: 'Líbano',
      fr: 'Liban',
      ja: 'レバノン',
      it: 'Libano',
      'zh-cn': '黎巴嫩',
      tr: 'Lübnan',
      ru: 'Ливан',
      uk: 'Ліван',
      pl: 'Liban'
    },
    emoji: '🇱🇧'
  },
  {
    name: 'Lesotho',
    code: 'LS',
    translations: {
      kr: '레소토',
      'pt-BR': 'Lesoto',
      pt: 'Lesoto',
      nl: 'Lesotho',
      hr: 'Lesoto',
      fa: 'لسوتو',
      de: 'Lesotho',
      es: 'Lesotho',
      fr: 'Lesotho',
      ja: 'レソト',
      it: 'Lesotho',
      'zh-cn': '莱索托',
      tr: 'Lesotho',
      ru: 'Лесото',
      uk: 'Лесото',
      pl: 'Lesotho'
    },
    emoji: '🇱🇸'
  },
  {
    name: 'Liberia',
    code: 'LR',
    translations: {
      kr: '라이베리아',
      'pt-BR': 'Libéria',
      pt: 'Libéria',
      nl: 'Liberia',
      hr: 'Liberija',
      fa: 'لیبریا',
      de: 'Liberia',
      es: 'Liberia',
      fr: 'Liberia',
      ja: 'リベリア',
      it: 'Liberia',
      'zh-cn': '利比里亚',
      tr: 'Liberya',
      ru: 'Либерия',
      uk: 'Ліберія',
      pl: 'Liberia'
    },
    emoji: '🇱🇷'
  },
  {
    name: 'Libya',
    code: 'LY',
    translations: {
      kr: '리비아',
      'pt-BR': 'Líbia',
      pt: 'Líbia',
      nl: 'Libië',
      hr: 'Libija',
      fa: 'لیبی',
      de: 'Libyen',
      es: 'Libia',
      fr: 'Libye',
      ja: 'リビア',
      it: 'Libia',
      'zh-cn': '利比亚',
      tr: 'Libya',
      ru: 'Ливия',
      uk: 'Лівія',
      pl: 'Libia'
    },
    emoji: '🇱🇾'
  },
  {
    name: 'Liechtenstein',
    code: 'LI',
    translations: {
      kr: '리히텐슈타인',
      'pt-BR': 'Liechtenstein',
      pt: 'Listenstaine',
      nl: 'Liechtenstein',
      hr: 'Lihtenštajn',
      fa: 'لیختن‌اشتاین',
      de: 'Liechtenstein',
      es: 'Liechtenstein',
      fr: 'Liechtenstein',
      ja: 'リヒテンシュタイン',
      it: 'Liechtenstein',
      'zh-cn': '列支敦士登',
      tr: 'Lihtenştayn',
      ru: 'Лихтенштейн',
      uk: 'Ліхтенштейн',
      pl: 'Liechtenstein'
    },
    emoji: '🇱🇮'
  },
  {
    name: 'Lithuania',
    code: 'LT',
    translations: {
      kr: '리투아니아',
      'pt-BR': 'Lituânia',
      pt: 'Lituânia',
      nl: 'Litouwen',
      hr: 'Litva',
      fa: 'لیتوانی',
      de: 'Litauen',
      es: 'Lituania',
      fr: 'Lituanie',
      ja: 'リトアニア',
      it: 'Lituania',
      'zh-cn': '立陶宛',
      tr: 'Litvanya',
      ru: 'Литва',
      uk: 'Литва',
      pl: 'Litwa'
    },
    emoji: '🇱🇹'
  },
  {
    name: 'Luxembourg',
    code: 'LU',
    translations: {
      kr: '룩셈부르크',
      'pt-BR': 'Luxemburgo',
      pt: 'Luxemburgo',
      nl: 'Luxemburg',
      hr: 'Luksemburg',
      fa: 'لوکزامبورگ',
      de: 'Luxemburg',
      es: 'Luxemburgo',
      fr: 'Luxembourg',
      ja: 'ルクセンブルク',
      it: 'Lussemburgo',
      'zh-cn': '卢森堡',
      tr: 'Lüksemburg',
      ru: 'Люксембург',
      uk: 'Люксембург',
      pl: 'Luksemburg'
    },
    emoji: '🇱🇺'
  },
  {
    name: 'Macau S.A.R.',
    code: 'MO',
    translations: {
      kr: '마카오',
      'pt-BR': 'Macau',
      pt: 'Macau',
      nl: 'Macao',
      hr: 'Makao',
      fa: 'مکائو',
      de: 'Macao',
      es: 'Macao',
      fr: 'Macao',
      ja: 'マカオ',
      it: 'Macao',
      'zh-cn': '中国澳门',
      tr: 'Makao',
      ru: 'Макао С.А.Р.',
      uk: 'САР Макао.',
      pl: 'Macau S.A.R.'
    },
    emoji: '🇲🇴'
  },
  {
    name: 'Madagascar',
    code: 'MG',
    translations: {
      kr: '마다가스카르',
      'pt-BR': 'Madagascar',
      pt: 'Madagáscar',
      nl: 'Madagaskar',
      hr: 'Madagaskar',
      fa: 'ماداگاسکار',
      de: 'Madagaskar',
      es: 'Madagascar',
      fr: 'Madagascar',
      ja: 'マダガスカル',
      it: 'Madagascar',
      'zh-cn': '马达加斯加',
      tr: 'Madagaskar',
      ru: 'Мадагаскар',
      uk: 'Мадагаскар',
      pl: 'Madagaskar'
    },
    emoji: '🇲🇬'
  },
  {
    name: 'Malawi',
    code: 'MW',
    translations: {
      kr: '말라위',
      'pt-BR': 'Malawi',
      pt: 'Malávi',
      nl: 'Malawi',
      hr: 'Malavi',
      fa: 'مالاوی',
      de: 'Malawi',
      es: 'Malawi',
      fr: 'Malawi',
      ja: 'マラウイ',
      it: 'Malawi',
      'zh-cn': '马拉维',
      tr: 'Malavi',
      ru: 'Малави',
      uk: 'Малаві',
      pl: 'Malawi'
    },
    emoji: '🇲🇼'
  },
  {
    name: 'Malaysia',
    code: 'MY',
    translations: {
      kr: '말레이시아',
      'pt-BR': 'Malásia',
      pt: 'Malásia',
      nl: 'Maleisië',
      hr: 'Malezija',
      fa: 'مالزی',
      de: 'Malaysia',
      es: 'Malasia',
      fr: 'Malaisie',
      ja: 'マレーシア',
      it: 'Malesia',
      'zh-cn': '马来西亚',
      tr: 'Malezya',
      ru: 'Малайзия',
      uk: 'Малайзія',
      pl: 'Malezja'
    },
    emoji: '🇲🇾'
  },
  {
    name: 'Maldives',
    code: 'MV',
    translations: {
      kr: '몰디브',
      'pt-BR': 'Maldivas',
      pt: 'Maldivas',
      nl: 'Maldiven',
      hr: 'Maldivi',
      fa: 'مالدیو',
      de: 'Malediven',
      es: 'Maldivas',
      fr: 'Maldives',
      ja: 'モルディブ',
      it: 'Maldive',
      'zh-cn': '马尔代夫',
      tr: 'Maldivler',
      ru: 'Мальдивы',
      uk: 'Мальдіви',
      pl: 'Malediwy'
    },
    emoji: '🇲🇻'
  },
  {
    name: 'Mali',
    code: 'ML',
    translations: {
      kr: '말리',
      'pt-BR': 'Mali',
      pt: 'Mali',
      nl: 'Mali',
      hr: 'Mali',
      fa: 'مالی',
      de: 'Mali',
      es: 'Mali',
      fr: 'Mali',
      ja: 'マリ',
      it: 'Mali',
      'zh-cn': '马里',
      tr: 'Mali',
      ru: 'Мали',
      uk: 'Малі',
      pl: 'Mali'
    },
    emoji: '🇲🇱'
  },
  {
    name: 'Malta',
    code: 'MT',
    translations: {
      kr: '몰타',
      'pt-BR': 'Malta',
      pt: 'Malta',
      nl: 'Malta',
      hr: 'Malta',
      fa: 'مالت',
      de: 'Malta',
      es: 'Malta',
      fr: 'Malte',
      ja: 'マルタ',
      it: 'Malta',
      'zh-cn': '马耳他',
      tr: 'Malta',
      ru: 'Мальта',
      uk: 'Мальта',
      pl: 'Malta'
    },
    emoji: '🇲🇹'
  },
  {
    name: 'Man (Isle of)',
    code: 'IM',
    translations: {
      kr: '맨 섬',
      'pt-BR': 'Ilha de Man',
      pt: 'Ilha de Man',
      nl: 'Isle of Man',
      hr: 'Otok Man',
      fa: 'جزیره من',
      de: 'Insel Man',
      es: 'Isla de Man',
      fr: 'Île de Man',
      ja: 'マン島',
      it: 'Isola di Man',
      'zh-cn': '马恩岛',
      tr: 'Man Adasi',
      ru: 'Мэн (остров)',
      uk: 'Мен (острів Мен)',
      pl: 'Man (Wyspa)'
    },
    emoji: '🇮🇲'
  },
  {
    name: 'Marshall Islands',
    code: 'MH',
    translations: {
      kr: '마셜 제도',
      'pt-BR': 'Ilhas Marshall',
      pt: 'Ilhas Marshall',
      nl: 'Marshalleilanden',
      hr: 'Maršalovi Otoci',
      fa: 'جزایر مارشال',
      de: 'Marshallinseln',
      es: 'Islas Marshall',
      fr: 'Îles Marshall',
      ja: 'マーシャル諸島',
      it: 'Isole Marshall',
      'zh-cn': '马绍尔群岛',
      tr: 'Marşal Adalari',
      ru: 'Маршалловы острова',
      uk: 'Маршаллові острови',
      pl: 'Wyspy Marshalla'
    },
    emoji: '🇲🇭'
  },
  {
    name: 'Martinique',
    code: 'MQ',
    translations: {
      kr: '마르티니크',
      'pt-BR': 'Martinica',
      pt: 'Martinica',
      nl: 'Martinique',
      hr: 'Martinique',
      fa: 'مونتسرات',
      de: 'Martinique',
      es: 'Martinica',
      fr: 'Martinique',
      ja: 'マルティニーク',
      it: 'Martinica',
      'zh-cn': '马提尼克岛',
      tr: 'Martinik',
      ru: 'Мартиника',
      uk: 'Мартініка',
      pl: 'Martynika'
    },
    emoji: '🇲🇶'
  },
  {
    name: 'Mauritania',
    code: 'MR',
    translations: {
      kr: '모리타니',
      'pt-BR': 'Mauritânia',
      pt: 'Mauritânia',
      nl: 'Mauritanië',
      hr: 'Mauritanija',
      fa: 'موریتانی',
      de: 'Mauretanien',
      es: 'Mauritania',
      fr: 'Mauritanie',
      ja: 'モーリタニア',
      it: 'Mauritania',
      'zh-cn': '毛里塔尼亚',
      tr: 'Moritanya',
      ru: 'Мавритания',
      uk: 'Мавританія',
      pl: 'Mauretania'
    },
    emoji: '🇲🇷'
  },
  {
    name: 'Mauritius',
    code: 'MU',
    translations: {
      kr: '모리셔스',
      'pt-BR': 'Maurício',
      pt: 'Maurícia',
      nl: 'Mauritius',
      hr: 'Mauricijus',
      fa: 'موریس',
      de: 'Mauritius',
      es: 'Mauricio',
      fr: 'Île Maurice',
      ja: 'モーリシャス',
      it: 'Mauritius',
      'zh-cn': '毛里求斯',
      tr: 'Morityus',
      ru: 'Маврикий',
      uk: 'Маврикій',
      pl: 'Mauritius'
    },
    emoji: '🇲🇺'
  },
  {
    name: 'Mayotte',
    code: 'YT',
    translations: {
      kr: '마요트',
      'pt-BR': 'Mayotte',
      pt: 'Mayotte',
      nl: 'Mayotte',
      hr: 'Mayotte',
      fa: 'مایوت',
      de: 'Mayotte',
      es: 'Mayotte',
      fr: 'Mayotte',
      ja: 'マヨット',
      it: 'Mayotte',
      'zh-cn': '马约特',
      tr: 'Mayotte',
      ru: 'Майотта',
      uk: 'Майотта',
      pl: 'Majotta'
    },
    emoji: '🇾🇹'
  },
  {
    name: 'Mexico',
    code: 'MX',
    translations: {
      kr: '멕시코',
      'pt-BR': 'México',
      pt: 'México',
      nl: 'Mexico',
      hr: 'Meksiko',
      fa: 'مکزیک',
      de: 'Mexiko',
      es: 'México',
      fr: 'Mexique',
      ja: 'メキシコ',
      it: 'Messico',
      'zh-cn': '墨西哥',
      tr: 'Meksika',
      ru: 'Мексика',
      uk: 'Мексика',
      pl: 'Meksyk'
    },
    emoji: '🇲🇽'
  },
  {
    name: 'Micronesia',
    code: 'FM',
    translations: {
      kr: '미크로네시아 연방',
      'pt-BR': 'Micronésia',
      pt: 'Micronésia',
      nl: 'Micronesië',
      hr: 'Mikronezija',
      fa: 'ایالات فدرال میکرونزی',
      de: 'Mikronesien',
      es: 'Micronesia',
      fr: 'Micronésie',
      ja: 'ミクロネシア連邦',
      it: 'Micronesia',
      'zh-cn': '密克罗尼西亚',
      tr: 'Mikronezya',
      ru: 'Микронезия',
      uk: 'Мікронезія',
      pl: 'Mikronezja'
    },
    emoji: '🇫🇲'
  },
  {
    name: 'Moldova',
    code: 'MD',
    translations: {
      kr: '몰도바',
      'pt-BR': 'Moldávia',
      pt: 'Moldávia',
      nl: 'Moldavië',
      hr: 'Moldova',
      fa: 'مولداوی',
      de: 'Moldawie',
      es: 'Moldavia',
      fr: 'Moldavie',
      ja: 'モルドバ共和国',
      it: 'Moldavia',
      'zh-cn': '摩尔多瓦',
      tr: 'Moldova',
      ru: 'Молдова',
      uk: 'Молдова',
      pl: 'Mołdawia'
    },
    emoji: '🇲🇩'
  },
  {
    name: 'Monaco',
    code: 'MC',
    translations: {
      kr: '모나코',
      'pt-BR': 'Mônaco',
      pt: 'Mónaco',
      nl: 'Monaco',
      hr: 'Monako',
      fa: 'موناکو',
      de: 'Monaco',
      es: 'Mónaco',
      fr: 'Monaco',
      ja: 'モナコ',
      it: 'Principato di Monaco',
      'zh-cn': '摩纳哥',
      tr: 'Monako',
      ru: 'Монако',
      uk: 'Монако',
      pl: 'Monako'
    },
    emoji: '🇲🇨'
  },
  {
    name: 'Mongolia',
    code: 'MN',
    translations: {
      kr: '몽골',
      'pt-BR': 'Mongólia',
      pt: 'Mongólia',
      nl: 'Mongolië',
      hr: 'Mongolija',
      fa: 'مغولستان',
      de: 'Mongolei',
      es: 'Mongolia',
      fr: 'Mongolie',
      ja: 'モンゴル',
      it: 'Mongolia',
      'zh-cn': '蒙古',
      tr: 'Moğolistan',
      ru: 'Монголия',
      uk: 'Монголія',
      pl: 'Mongolia'
    },
    emoji: '🇲🇳'
  },
  {
    name: 'Montenegro',
    code: 'ME',
    translations: {
      kr: '몬테네그로',
      'pt-BR': 'Montenegro',
      pt: 'Montenegro',
      nl: 'Montenegro',
      hr: 'Crna Gora',
      fa: 'مونته‌نگرو',
      de: 'Montenegro',
      es: 'Montenegro',
      fr: 'Monténégro',
      ja: 'モンテネグロ',
      it: 'Montenegro',
      'zh-cn': '黑山',
      tr: 'Karadağ',
      ru: 'Черногория',
      uk: 'Чорногорія',
      pl: 'Czarnogóra'
    },
    emoji: '🇲🇪'
  },
  {
    name: 'Montserrat',
    code: 'MS',
    translations: {
      kr: '몬트세랫',
      'pt-BR': 'Montserrat',
      pt: 'Monserrate',
      nl: 'Montserrat',
      hr: 'Montserrat',
      fa: 'مایوت',
      de: 'Montserrat',
      es: 'Montserrat',
      fr: 'Montserrat',
      ja: 'モントセラト',
      it: 'Montserrat',
      'zh-cn': '蒙特塞拉特',
      tr: 'Montserrat',
      ru: 'Монтсеррат',
      uk: 'Монтсеррат',
      pl: 'Montserrat'
    },
    emoji: '🇲🇸'
  },
  {
    name: 'Morocco',
    code: 'MA',
    translations: {
      kr: '모로코',
      'pt-BR': 'Marrocos',
      pt: 'Marrocos',
      nl: 'Marokko',
      hr: 'Maroko',
      fa: 'مراکش',
      de: 'Marokko',
      es: 'Marruecos',
      fr: 'Maroc',
      ja: 'モロッコ',
      it: 'Marocco',
      'zh-cn': '摩洛哥',
      tr: 'Fas',
      ru: 'Марокко',
      uk: 'Марокко',
      pl: 'Maroko'
    },
    emoji: '🇲🇦'
  },
  {
    name: 'Mozambique',
    code: 'MZ',
    translations: {
      kr: '모잠비크',
      'pt-BR': 'Moçambique',
      pt: 'Moçambique',
      nl: 'Mozambique',
      hr: 'Mozambik',
      fa: 'موزامبیک',
      de: 'Mosambik',
      es: 'Mozambique',
      fr: 'Mozambique',
      ja: 'モザンビーク',
      it: 'Mozambico',
      'zh-cn': '莫桑比克',
      tr: 'Mozambik',
      ru: 'Мозамбик',
      uk: 'Мозамбік',
      pl: 'Mozambik'
    },
    emoji: '🇲🇿'
  },
  {
    name: 'Myanmar',
    code: 'MM',
    translations: {
      kr: '미얀마',
      'pt-BR': 'Myanmar',
      pt: 'Myanmar',
      nl: 'Myanmar',
      hr: 'Mijanmar',
      fa: 'میانمار',
      de: 'Myanmar',
      es: 'Myanmar',
      fr: 'Myanmar',
      ja: 'ミャンマー',
      it: 'Birmania',
      'zh-cn': '缅甸',
      tr: 'Myanmar',
      ru: 'Мьянма',
      uk: "М'янма",
      pl: 'Birma'
    },
    emoji: '🇲🇲'
  },
  {
    name: 'Namibia',
    code: 'NA',
    translations: {
      kr: '나미비아',
      'pt-BR': 'Namíbia',
      pt: 'Namíbia',
      nl: 'Namibië',
      hr: 'Namibija',
      fa: 'نامیبیا',
      de: 'Namibia',
      es: 'Namibia',
      fr: 'Namibie',
      ja: 'ナミビア',
      it: 'Namibia',
      'zh-cn': '纳米比亚',
      tr: 'Namibya',
      ru: 'Намибия',
      uk: 'Намібія',
      pl: 'Namibia'
    },
    emoji: '🇳🇦'
  },
  {
    name: 'Nauru',
    code: 'NR',
    translations: {
      kr: '나우루',
      'pt-BR': 'Nauru',
      pt: 'Nauru',
      nl: 'Nauru',
      hr: 'Nauru',
      fa: 'نائورو',
      de: 'Nauru',
      es: 'Nauru',
      fr: 'Nauru',
      ja: 'ナウル',
      it: 'Nauru',
      'zh-cn': '瑙鲁',
      tr: 'Nauru',
      ru: 'Науру',
      uk: 'Науру',
      pl: 'Nauru'
    },
    emoji: '🇳🇷'
  },
  {
    name: 'Nepal',
    code: 'NP',
    translations: {
      kr: '네팔',
      'pt-BR': 'Nepal',
      pt: 'Nepal',
      nl: 'Nepal',
      hr: 'Nepal',
      fa: 'نپال',
      de: 'Népal',
      es: 'Nepal',
      fr: 'Népal',
      ja: 'ネパール',
      it: 'Nepal',
      'zh-cn': '尼泊尔',
      tr: 'Nepal',
      ru: 'Непал',
      uk: 'Непал',
      pl: 'Nepal'
    },
    emoji: '🇳🇵'
  },
  {
    name: 'Netherlands',
    code: 'NL',
    translations: {
      kr: '네덜란드 ',
      'pt-BR': 'Holanda',
      pt: 'Países Baixos',
      nl: 'Nederland',
      hr: 'Nizozemska',
      fa: 'پادشاهی هلند',
      de: 'Niederlande',
      es: 'Países Bajos',
      fr: 'Pays-Bas',
      ja: 'オランダ',
      it: 'Paesi Bassi',
      'zh-cn': '荷兰',
      tr: 'Hollanda',
      ru: 'Нидерланды',
      uk: 'Нідерланди',
      pl: 'Holandia'
    },
    emoji: '🇳🇱'
  },
  {
    name: 'New Caledonia',
    code: 'NC',
    translations: {
      kr: '누벨칼레도니',
      'pt-BR': 'Nova Caledônia',
      pt: 'Nova Caledónia',
      nl: 'Nieuw-Caledonië',
      hr: 'Nova Kaledonija',
      fa: 'کالدونیای جدید',
      de: 'Neukaledonien',
      es: 'Nueva Caledonia',
      fr: 'Nouvelle-Calédonie',
      ja: 'ニューカレドニア',
      it: 'Nuova Caledonia',
      'zh-cn': '新喀里多尼亚',
      tr: 'Yeni Kaledonya',
      ru: 'Новая Каледония',
      uk: 'Нова Каледонія',
      pl: 'Nowa Kaledonia'
    },
    emoji: '🇳🇨'
  },
  {
    name: 'New Zealand',
    code: 'NZ',
    translations: {
      kr: '뉴질랜드',
      'pt-BR': 'Nova Zelândia',
      pt: 'Nova Zelândia',
      nl: 'Nieuw-Zeeland',
      hr: 'Novi Zeland',
      fa: 'نیوزیلند',
      de: 'Neuseeland',
      es: 'Nueva Zelanda',
      fr: 'Nouvelle-Zélande',
      ja: 'ニュージーランド',
      it: 'Nuova Zelanda',
      'zh-cn': '新西兰',
      tr: 'Yeni Zelanda',
      ru: 'Новая Зеландия',
      uk: 'Нова Зеландія',
      pl: 'Nowa Zelandia'
    },
    emoji: '🇳🇿'
  },
  {
    name: 'Nicaragua',
    code: 'NI',
    translations: {
      kr: '니카라과',
      'pt-BR': 'Nicarágua',
      pt: 'Nicarágua',
      nl: 'Nicaragua',
      hr: 'Nikaragva',
      fa: 'نیکاراگوئه',
      de: 'Nicaragua',
      es: 'Nicaragua',
      fr: 'Nicaragua',
      ja: 'ニカラグア',
      it: 'Nicaragua',
      'zh-cn': '尼加拉瓜',
      tr: 'Nikaragua',
      ru: 'Никарагуа',
      uk: 'Нікарагуа',
      pl: 'Nikaragua'
    },
    emoji: '🇳🇮'
  },
  {
    name: 'Niger',
    code: 'NE',
    translations: {
      kr: '니제르',
      'pt-BR': 'Níger',
      pt: 'Níger',
      nl: 'Niger',
      hr: 'Niger',
      fa: 'نیجر',
      de: 'Niger',
      es: 'Níger',
      fr: 'Niger',
      ja: 'ニジェール',
      it: 'Niger',
      'zh-cn': '尼日尔',
      tr: 'Nijer',
      ru: 'Нигер',
      uk: 'Нігер',
      pl: 'Niger'
    },
    emoji: '🇳🇪'
  },
  {
    name: 'Nigeria',
    code: 'NG',
    translations: {
      kr: '나이지리아',
      'pt-BR': 'Nigéria',
      pt: 'Nigéria',
      nl: 'Nigeria',
      hr: 'Nigerija',
      fa: 'نیجریه',
      de: 'Nigeria',
      es: 'Nigeria',
      fr: 'Nigéria',
      ja: 'ナイジェリア',
      it: 'Nigeria',
      'zh-cn': '尼日利亚',
      tr: 'Nijerya',
      ru: 'Нигерия',
      uk: 'Нігерія',
      pl: 'Nigeria'
    },
    emoji: '🇳🇬'
  },
  {
    name: 'Niue',
    code: 'NU',
    translations: {
      kr: '니우에',
      'pt-BR': 'Niue',
      pt: 'Niue',
      nl: 'Niue',
      hr: 'Niue',
      fa: 'نیووی',
      de: 'Niue',
      es: 'Niue',
      fr: 'Niue',
      ja: 'ニウエ',
      it: 'Niue',
      'zh-cn': '纽埃',
      tr: 'Niue',
      ru: 'Ниуэ',
      uk: 'Ніуе',
      pl: 'Niue'
    },
    emoji: '🇳🇺'
  },
  {
    name: 'Norfolk Island',
    code: 'NF',
    translations: {
      kr: '노퍽 섬',
      'pt-BR': 'Ilha Norfolk',
      pt: 'Ilha Norfolk',
      nl: 'Norfolkeiland',
      hr: 'Otok Norfolk',
      fa: 'جزیره نورفک',
      de: 'Norfolkinsel',
      es: 'Isla de Norfolk',
      fr: 'Île de Norfolk',
      ja: 'ノーフォーク島',
      it: 'Isola Norfolk',
      'zh-cn': '诺福克岛',
      tr: 'Norfolk Adasi',
      ru: 'Остров Норфолк',
      uk: 'Острів Норфолк',
      pl: 'Wyspa Norfolk'
    },
    emoji: '🇳🇫'
  },
  {
    name: 'North Korea',
    code: 'KP',
    translations: {
      kr: '조선민주주의인민공화국',
      'pt-BR': 'Coreia do Norte',
      pt: 'Coreia do Norte',
      nl: 'Noord-Korea',
      hr: 'Sjeverna Koreja',
      fa: 'کره جنوبی',
      de: 'Nordkorea',
      es: 'Corea del Norte',
      fr: 'Corée du Nord',
      ja: '朝鮮民主主義人民共和国',
      it: 'Corea del Nord',
      'zh-cn': '朝鲜',
      tr: 'Kuzey Kore',
      ru: 'Северная Корея',
      uk: 'Північна Корея',
      pl: 'Korea Północna'
    },
    emoji: '🇰🇵'
  },
  {
    name: 'North Macedonia',
    code: 'MK',
    translations: {
      kr: '북마케도니아',
      'pt-BR': 'Macedônia do Norte',
      pt: 'Macedónia do Norte',
      nl: 'Noord-Macedonië',
      hr: 'Sjeverna Makedonija',
      fa: 'ﻢﻗﺩﻮﻨﯿﻫ ﺶﻣﺎﻠﯾ',
      de: 'Nordmazedonien',
      es: 'Macedonia del Norte',
      fr: 'Macédoine du Nord',
      ja: '北マケドニア',
      it: 'Macedonia del Nord',
      'zh-cn': '北馬其頓',
      tr: 'Kuzey Makedonya',
      ru: 'Северная Македония',
      uk: 'Північна Македонія',
      pl: 'Macedonia Północna'
    },
    emoji: '🇲🇰'
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MP',
    translations: {
      kr: '북마리아나 제도',
      'pt-BR': 'Ilhas Marianas',
      pt: 'Ilhas Marianas',
      nl: 'Noordelijke Marianeneilanden',
      hr: 'Sjevernomarijanski otoci',
      fa: 'جزایر ماریانای شمالی',
      de: 'Nördliche Marianen',
      es: 'Islas Marianas del Norte',
      fr: 'Îles Mariannes du Nord',
      ja: '北マリアナ諸島',
      it: 'Isole Marianne Settentrionali',
      'zh-cn': '北马里亚纳群岛',
      tr: 'Kuzey Mariana Adalari',
      ru: 'Северные Марианские острова',
      uk: 'Північні Маріанські острови',
      pl: 'Mariany Północne'
    },
    emoji: '🇲🇵'
  },
  {
    name: 'Norway',
    code: 'NO',
    translations: {
      kr: '노르웨이',
      'pt-BR': 'Noruega',
      pt: 'Noruega',
      nl: 'Noorwegen',
      hr: 'Norveška',
      fa: 'نروژ',
      de: 'Norwegen',
      es: 'Noruega',
      fr: 'Norvège',
      ja: 'ノルウェー',
      it: 'Norvegia',
      'zh-cn': '挪威',
      tr: 'Norveç',
      ru: 'Норвегия',
      uk: 'Норвегія',
      pl: 'Norwegia'
    },
    emoji: '🇳🇴'
  },
  {
    name: 'Oman',
    code: 'OM',
    translations: {
      kr: '오만',
      'pt-BR': 'Omã',
      pt: 'Omã',
      nl: 'Oman',
      hr: 'Oman',
      fa: 'عمان',
      de: 'Oman',
      es: 'Omán',
      fr: 'Oman',
      ja: 'オマーン',
      it: 'oman',
      'zh-cn': '阿曼',
      tr: 'Umman',
      ru: 'Оман',
      uk: 'Оман',
      pl: 'Oman'
    },
    emoji: '🇴🇲'
  },
  {
    name: 'Pakistan',
    code: 'PK',
    translations: {
      kr: '파키스탄',
      'pt-BR': 'Paquistão',
      pt: 'Paquistão',
      nl: 'Pakistan',
      hr: 'Pakistan',
      fa: 'پاکستان',
      de: 'Pakistan',
      es: 'Pakistán',
      fr: 'Pakistan',
      ja: 'パキスタン',
      it: 'Pakistan',
      'zh-cn': '巴基斯坦',
      tr: 'Pakistan',
      ru: 'Пакистан',
      uk: 'Пакистан',
      pl: 'Pakistan'
    },
    emoji: '🇵🇰'
  },
  {
    name: 'Palau',
    code: 'PW',
    translations: {
      kr: '팔라우',
      'pt-BR': 'Palau',
      pt: 'Palau',
      nl: 'Palau',
      hr: 'Palau',
      fa: 'پالائو',
      de: 'Palau',
      es: 'Palau',
      fr: 'Palaos',
      ja: 'パラオ',
      it: 'Palau',
      'zh-cn': '帕劳',
      tr: 'Palau',
      ru: 'Палау',
      uk: 'Палау',
      pl: 'Palau'
    },
    emoji: '🇵🇼'
  },
  {
    name: 'Palestinian Territory Occupied',
    code: 'PS',
    translations: {
      kr: '팔레스타인 영토',
      'pt-BR': 'Palestina',
      pt: 'Palestina',
      nl: 'Palestijnse gebieden',
      hr: 'Palestina',
      fa: 'فلسطین',
      de: 'Palästina',
      es: 'Palestina',
      fr: 'Palestine',
      ja: 'パレスチナ',
      it: 'Palestina',
      'zh-cn': '巴勒斯坦',
      tr: 'Filistin',
      ru: 'Оккупированная палестинская территория',
      uk: 'Окупована палестинська територія',
      pl: 'Okupowane terytorium palestyńskie'
    },
    emoji: '🇵🇸'
  },
  {
    name: 'Panama',
    code: 'PA',
    translations: {
      kr: '파나마',
      'pt-BR': 'Panamá',
      pt: 'Panamá',
      nl: 'Panama',
      hr: 'Panama',
      fa: 'پاناما',
      de: 'Panama',
      es: 'Panamá',
      fr: 'Panama',
      ja: 'パナマ',
      it: 'Panama',
      'zh-cn': '巴拿马',
      tr: 'Panama',
      ru: 'Панама',
      uk: 'Панама',
      pl: 'Panama'
    },
    emoji: '🇵🇦'
  },
  {
    name: 'Papua New Guinea',
    code: 'PG',
    translations: {
      kr: '파푸아뉴기니',
      'pt-BR': 'Papua Nova Guiné',
      pt: 'Papua Nova Guiné',
      nl: 'Papoea-Nieuw-Guinea',
      hr: 'Papua Nova Gvineja',
      fa: 'پاپوآ گینه نو',
      de: 'Papua-Neuguinea',
      es: 'Papúa Nueva Guinea',
      fr: 'Papouasie-Nouvelle-Guinée',
      ja: 'パプアニューギニア',
      it: 'Papua Nuova Guinea',
      'zh-cn': '巴布亚新几内亚',
      tr: 'Papua Yeni Gine',
      ru: 'Папуа - Новая Гвинея',
      uk: 'Папуа-Нова Гвінея',
      pl: 'Papua-Nowa Gwinea'
    },
    emoji: '🇵🇬'
  },
  {
    name: 'Paraguay',
    code: 'PY',
    translations: {
      kr: '파라과이',
      'pt-BR': 'Paraguai',
      pt: 'Paraguai',
      nl: 'Paraguay',
      hr: 'Paragvaj',
      fa: 'پاراگوئه',
      de: 'Paraguay',
      es: 'Paraguay',
      fr: 'Paraguay',
      ja: 'パラグアイ',
      it: 'Paraguay',
      'zh-cn': '巴拉圭',
      tr: 'Paraguay',
      ru: 'Парагвай',
      uk: 'Парагвай',
      pl: 'Paragwaj'
    },
    emoji: '🇵🇾'
  },
  {
    name: 'Peru',
    code: 'PE',
    translations: {
      kr: '페루',
      'pt-BR': 'Peru',
      pt: 'Peru',
      nl: 'Peru',
      hr: 'Peru',
      fa: 'پرو',
      de: 'Peru',
      es: 'Perú',
      fr: 'Pérou',
      ja: 'ペルー',
      it: 'Perù',
      'zh-cn': '秘鲁',
      tr: 'Peru',
      ru: 'Перу',
      uk: 'Перу',
      pl: 'Peru'
    },
    emoji: '🇵🇪'
  },
  {
    name: 'Philippines',
    code: 'PH',
    translations: {
      kr: '필리핀',
      'pt-BR': 'Filipinas',
      pt: 'Filipinas',
      nl: 'Filipijnen',
      hr: 'Filipini',
      fa: 'جزایر الندفیلیپین',
      de: 'Philippinen',
      es: 'Filipinas',
      fr: 'Philippines',
      ja: 'フィリピン',
      it: 'Filippine',
      'zh-cn': '菲律宾',
      tr: 'Filipinler',
      ru: 'Филиппины',
      uk: 'Філіппіни',
      pl: 'Filipiny'
    },
    emoji: '🇵🇭'
  },
  {
    name: 'Pitcairn Island',
    code: 'PN',
    translations: {
      kr: '핏케언 제도',
      'pt-BR': 'Ilhas Pitcairn',
      pt: 'Ilhas Picárnia',
      nl: 'Pitcairneilanden',
      hr: 'Pitcairnovo otočje',
      fa: 'پیتکرن',
      de: 'Pitcairn',
      es: 'Islas Pitcairn',
      fr: 'Îles Pitcairn',
      ja: 'ピトケアン',
      it: 'Isole Pitcairn',
      'zh-cn': '皮特凯恩群岛',
      tr: 'Pitcairn Adalari',
      ru: 'Остров Питкэрн',
      uk: 'Острів Піткерн',
      pl: 'Wyspa Pitcairn'
    },
    emoji: '🇵🇳'
  },
  {
    name: 'Poland',
    code: 'PL',
    translations: {
      kr: '폴란드',
      'pt-BR': 'Polônia',
      pt: 'Polónia',
      nl: 'Polen',
      hr: 'Poljska',
      fa: 'لهستان',
      de: 'Polen',
      es: 'Polonia',
      fr: 'Pologne',
      ja: 'ポーランド',
      it: 'Polonia',
      'zh-cn': '波兰',
      tr: 'Polonya',
      ru: 'Польша',
      uk: 'Польща',
      pl: 'Polska'
    },
    emoji: '🇵🇱'
  },
  {
    name: 'Portugal',
    code: 'PT',
    translations: {
      kr: '포르투갈',
      'pt-BR': 'Portugal',
      pt: 'Portugal',
      nl: 'Portugal',
      hr: 'Portugal',
      fa: 'پرتغال',
      de: 'Portugal',
      es: 'Portugal',
      fr: 'Portugal',
      ja: 'ポルトガル',
      it: 'Portogallo',
      'zh-cn': '葡萄牙',
      tr: 'Portekiz',
      ru: 'Португалия',
      uk: 'Португалія',
      pl: 'Portugalia'
    },
    emoji: '🇵🇹'
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
    translations: {
      kr: '푸에르토리코',
      'pt-BR': 'Porto Rico',
      pt: 'Porto Rico',
      nl: 'Puerto Rico',
      hr: 'Portoriko',
      fa: 'پورتو ریکو',
      de: 'Puerto Rico',
      es: 'Puerto Rico',
      fr: 'Porto Rico',
      ja: 'プエルトリコ',
      it: 'Porto Rico',
      'zh-cn': '波多黎各',
      tr: 'Porto Riko',
      ru: 'Пуэрто-Рико',
      uk: 'Пуерто-Ріко',
      pl: 'Portoryko'
    },
    emoji: '🇵🇷'
  },
  {
    name: 'Qatar',
    code: 'QA',
    translations: {
      kr: '카타르',
      'pt-BR': 'Catar',
      pt: 'Catar',
      nl: 'Qatar',
      hr: 'Katar',
      fa: 'قطر',
      de: 'Katar',
      es: 'Catar',
      fr: 'Qatar',
      ja: 'カタール',
      it: 'Qatar',
      'zh-cn': '卡塔尔',
      tr: 'Katar',
      ru: 'Катар',
      uk: 'Катар',
      pl: 'Katar'
    },
    emoji: '🇶🇦'
  },
  {
    name: 'Reunion',
    code: 'RE',
    translations: {
      kr: '레위니옹',
      'pt-BR': 'Reunião',
      pt: 'Reunião',
      nl: 'Réunion',
      hr: 'Réunion',
      fa: 'رئونیون',
      de: 'Réunion',
      es: 'Reunión',
      fr: 'Réunion',
      ja: 'レユニオン',
      it: 'Riunione',
      'zh-cn': '留尼汪岛',
      tr: 'Réunion',
      ru: 'Воссоединение',
      uk: "Возз'єднання",
      pl: 'Reunion'
    },
    emoji: '🇷🇪'
  },
  {
    name: 'Romania',
    code: 'RO',
    translations: {
      kr: '루마니아',
      'pt-BR': 'Romênia',
      pt: 'Roménia',
      nl: 'Roemenië',
      hr: 'Rumunjska',
      fa: 'رومانی',
      de: 'Rumänien',
      es: 'Rumania',
      fr: 'Roumanie',
      ja: 'ルーマニア',
      it: 'Romania',
      'zh-cn': '罗马尼亚',
      tr: 'Romanya',
      ru: 'Румыния',
      uk: 'Румунія',
      pl: 'Rumunia'
    },
    emoji: '🇷🇴'
  },
  {
    name: 'Russia',
    code: 'RU',
    translations: {
      kr: '러시아',
      'pt-BR': 'Rússia',
      pt: 'Rússia',
      nl: 'Rusland',
      hr: 'Rusija',
      fa: 'روسیه',
      de: 'Russland',
      es: 'Rusia',
      fr: 'Russie',
      ja: 'ロシア連邦',
      it: 'Russia',
      'zh-cn': '俄罗斯联邦',
      tr: 'Rusya',
      ru: 'Россия',
      uk: 'Росія',
      pl: 'Rosja'
    },
    emoji: '🇷🇺'
  },
  {
    name: 'Rwanda',
    code: 'RW',
    translations: {
      kr: '르완다',
      'pt-BR': 'Ruanda',
      pt: 'Ruanda',
      nl: 'Rwanda',
      hr: 'Ruanda',
      fa: 'رواندا',
      de: 'Ruanda',
      es: 'Ruanda',
      fr: 'Rwanda',
      ja: 'ルワンダ',
      it: 'Ruanda',
      'zh-cn': '卢旺达',
      tr: 'Ruanda',
      ru: 'Руанда',
      uk: 'Руанда',
      pl: 'Rwanda'
    },
    emoji: '🇷🇼'
  },
  {
    name: 'Saint Helena',
    code: 'SH',
    translations: {
      kr: '세인트헬레나',
      'pt-BR': 'Santa Helena',
      pt: 'Santa Helena',
      nl: 'Sint-Helena',
      hr: 'Sveta Helena',
      fa: 'سنت هلنا، اسنشن و تریستان دا کونا',
      de: 'Sankt Helena',
      es: 'Santa Helena',
      fr: 'Sainte-Hélène',
      ja: 'セントヘレナ・アセンションおよびトリスタンダクーニャ',
      it: "Sant'Elena",
      'zh-cn': '圣赫勒拿',
      tr: 'Saint Helena',
      ru: 'Святая Елена',
      uk: 'Свята Єлена',
      pl: 'Święta Helena'
    },
    emoji: '🇸🇭'
  },
  {
    name: 'Saint Kitts and Nevis',
    code: 'KN',
    translations: {
      kr: '세인트키츠 네비스',
      'pt-BR': 'São Cristóvão e Neves',
      pt: 'São Cristóvão e Neves',
      nl: 'Saint Kitts en Nevis',
      hr: 'Sveti Kristof i Nevis',
      fa: 'سنت کیتس و نویس',
      de: 'St. Kitts und Nevis',
      es: 'San Cristóbal y Nieves',
      fr: 'Saint-Christophe-et-Niévès',
      ja: 'セントクリストファー・ネイビス',
      it: 'Saint Kitts e Nevis',
      'zh-cn': '圣基茨和尼维斯',
      tr: 'Saint Kitts Ve Nevis',
      ru: 'Сент-Китс и Невис',
      uk: 'Сент-Кітс і Невіс',
      pl: 'Saint Kitts i Nevis'
    },
    emoji: '🇰🇳'
  },
  {
    name: 'Saint Lucia',
    code: 'LC',
    translations: {
      kr: '세인트루시아',
      'pt-BR': 'Santa Lúcia',
      pt: 'Santa Lúcia',
      nl: 'Saint Lucia',
      hr: 'Sveta Lucija',
      fa: 'سنت لوسیا',
      de: 'Saint Lucia',
      es: 'Santa Lucía',
      fr: 'Saint-Lucie',
      ja: 'セントルシア',
      it: 'Santa Lucia',
      'zh-cn': '圣卢西亚',
      tr: 'Saint Lucia',
      ru: 'Сент-Люсия',
      uk: 'Сент-Люсія',
      pl: 'Saint Lucia'
    },
    emoji: '🇱🇨'
  },
  {
    name: 'Saint Pierre and Miquelon',
    code: 'PM',
    translations: {
      kr: '생피에르 미클롱',
      'pt-BR': 'Saint-Pierre e Miquelon',
      pt: 'São Pedro e Miquelon',
      nl: 'Saint Pierre en Miquelon',
      hr: 'Sveti Petar i Mikelon',
      fa: 'سن پیر و میکلن',
      de: 'Saint-Pierre und Miquelon',
      es: 'San Pedro y Miquelón',
      fr: 'Saint-Pierre-et-Miquelon',
      ja: 'サンピエール島・ミクロン島',
      it: 'Saint-Pierre e Miquelon',
      'zh-cn': '圣皮埃尔和密克隆',
      tr: 'Saint Pierre Ve Miquelon',
      ru: 'Сен-Пьер и Микелон',
      uk: "Сен-П'єр і Мікелон",
      pl: 'Saint-Pierre i Miquelon'
    },
    emoji: '🇵🇲'
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: 'VC',
    translations: {
      kr: '세인트빈센트 그레나딘',
      'pt-BR': 'São Vicente e Granadinas',
      pt: 'São Vicente e Granadinas',
      nl: 'Saint Vincent en de Grenadines',
      hr: 'Sveti Vincent i Grenadini',
      fa: 'سنت وینسنت و گرنادین‌ها',
      de: 'Saint Vincent und die Grenadinen',
      es: 'San Vicente y Granadinas',
      fr: 'Saint-Vincent-et-les-Grenadines',
      ja: 'セントビンセントおよびグレナディーン諸島',
      it: 'Saint Vincent e Grenadine',
      'zh-cn': '圣文森特和格林纳丁斯',
      tr: 'Saint Vincent Ve Grenadinler',
      ru: 'Сент-Винсент и Гренадины',
      uk: 'Сент-Вінсент і Гренадини',
      pl: 'Saint Vincent i Grenadyny'
    },
    emoji: '🇻🇨'
  },
  {
    name: 'Saint-Barthelemy',
    code: 'BL',
    translations: {
      kr: '생바르텔레미',
      'pt-BR': 'São Bartolomeu',
      pt: 'São Bartolomeu',
      nl: 'Saint Barthélemy',
      hr: 'Saint Barthélemy',
      fa: 'سن-بارتلمی',
      de: 'Saint-Barthélemy',
      es: 'San Bartolomé',
      fr: 'Saint-Barthélemy',
      ja: 'サン・バルテルミー',
      it: 'Antille Francesi',
      'zh-cn': '圣巴泰勒米',
      tr: 'Saint Barthélemy',
      ru: 'Сен-Бартелеми',
      uk: 'Сен-Бартелемі',
      pl: 'Saint-Barthelemy'
    },
    emoji: '🇧🇱'
  },
  {
    name: 'Saint-Martin (French part)',
    code: 'MF',
    translations: {
      kr: '세인트마틴 섬',
      'pt-BR': 'Saint Martin',
      pt: 'Ilha São Martinho',
      nl: 'Saint-Martin',
      hr: 'Sveti Martin',
      fa: 'سینت مارتن',
      de: 'Saint Martin',
      es: 'Saint Martin',
      fr: 'Saint-Martin',
      ja: 'サン・マルタン（フランス領）',
      it: 'Saint Martin',
      'zh-cn': '密克罗尼西亚',
      tr: 'Saint Martin',
      ru: 'Сен-Мартен (французская часть)',
      uk: 'Сен-Мартен (французька частина)',
      pl: 'Saint-Martin (część francuska)'
    },
    emoji: '🇲🇫'
  },
  {
    name: 'Samoa',
    code: 'WS',
    translations: {
      kr: '사모아',
      'pt-BR': 'Samoa',
      pt: 'Samoa',
      nl: 'Samoa',
      hr: 'Samoa',
      fa: 'ساموآ',
      de: 'Samoa',
      es: 'Samoa',
      fr: 'Samoa',
      ja: 'サモア',
      it: 'Samoa',
      'zh-cn': '萨摩亚',
      tr: 'Samoa',
      ru: 'Самоа',
      uk: 'Самоа',
      pl: 'Samoa'
    },
    emoji: '🇼🇸'
  },
  {
    name: 'San Marino',
    code: 'SM',
    translations: {
      kr: '산마리노',
      'pt-BR': 'San Marino',
      pt: 'São Marinho',
      nl: 'San Marino',
      hr: 'San Marino',
      fa: 'سان مارینو',
      de: 'San Marino',
      es: 'San Marino',
      fr: 'Saint-Marin',
      ja: 'サンマリノ',
      it: 'San Marino',
      'zh-cn': '圣马力诺',
      tr: 'San Marino',
      ru: 'Сан-Марино',
      uk: 'Сан-Марино',
      pl: 'San Marino'
    },
    emoji: '🇸🇲'
  },
  {
    name: 'Sao Tome and Principe',
    code: 'ST',
    translations: {
      kr: '상투메 프린시페',
      'pt-BR': 'São Tomé e Príncipe',
      pt: 'São Tomé e Príncipe',
      nl: 'Sao Tomé en Principe',
      hr: 'Sveti Toma i Princip',
      fa: 'کواترو دو فرویرو',
      de: 'São Tomé und Príncipe',
      es: 'Santo Tomé y Príncipe',
      fr: 'Sao Tomé-et-Principe',
      ja: 'サントメ・プリンシペ',
      it: 'São Tomé e Príncipe',
      'zh-cn': '圣多美和普林西比',
      tr: 'Sao Tome Ve Prinsipe',
      ru: 'Сан-Томе и Принсипи',
      uk: 'Сан-Томе і Принсіпі',
      pl: 'Wyspy Świętego Tomasza i Książęca'
    },
    emoji: '🇸🇹'
  },
  {
    name: 'Saudi Arabia',
    code: 'SA',
    translations: {
      kr: '사우디아라비아',
      'pt-BR': 'Arábia Saudita',
      pt: 'Arábia Saudita',
      nl: 'Saoedi-Arabië',
      hr: 'Saudijska Arabija',
      fa: 'عربستان سعودی',
      de: 'Saudi-Arabien',
      es: 'Arabia Saudí',
      fr: 'Arabie Saoudite',
      ja: 'サウジアラビア',
      it: 'Arabia Saudita',
      'zh-cn': '沙特阿拉伯',
      tr: 'Suudi Arabistan',
      ru: 'Саудовская Аравия',
      uk: 'Саудівська Аравія',
      pl: 'Arabia Saudyjska'
    },
    emoji: '🇸🇦'
  },
  {
    name: 'Senegal',
    code: 'SN',
    translations: {
      kr: '세네갈',
      'pt-BR': 'Senegal',
      pt: 'Senegal',
      nl: 'Senegal',
      hr: 'Senegal',
      fa: 'سنگال',
      de: 'Senegal',
      es: 'Senegal',
      fr: 'Sénégal',
      ja: 'セネガル',
      it: 'Senegal',
      'zh-cn': '塞内加尔',
      tr: 'Senegal',
      ru: 'Сенегал',
      uk: 'Сенегал',
      pl: 'Senegal'
    },
    emoji: '🇸🇳'
  },
  {
    name: 'Serbia',
    code: 'RS',
    translations: {
      kr: '세르비아',
      'pt-BR': 'Sérvia',
      pt: 'Sérvia',
      nl: 'Servië',
      hr: 'Srbija',
      fa: 'صربستان',
      de: 'Serbien',
      es: 'Serbia',
      fr: 'Serbie',
      ja: 'セルビア',
      it: 'Serbia',
      'zh-cn': '塞尔维亚',
      tr: 'Sirbistan',
      ru: 'Сербия',
      uk: 'Сербія',
      pl: 'Serbia'
    },
    emoji: '🇷🇸'
  },
  {
    name: 'Seychelles',
    code: 'SC',
    translations: {
      kr: '세이셸',
      'pt-BR': 'Seicheles',
      pt: 'Seicheles',
      nl: 'Seychellen',
      hr: 'Sejšeli',
      fa: 'سیشل',
      de: 'Seychellen',
      es: 'Seychelles',
      fr: 'Seychelles',
      ja: 'セーシェル',
      it: 'Seychelles',
      'zh-cn': '塞舌尔',
      tr: 'Seyşeller',
      ru: 'Сейшельские острова',
      uk: 'Сейшельські острови',
      pl: 'Seszele'
    },
    emoji: '🇸🇨'
  },
  {
    name: 'Sierra Leone',
    code: 'SL',
    translations: {
      kr: '시에라리온',
      'pt-BR': 'Serra Leoa',
      pt: 'Serra Leoa',
      nl: 'Sierra Leone',
      hr: 'Sijera Leone',
      fa: 'سیرالئون',
      de: 'Sierra Leone',
      es: 'Sierra Leone',
      fr: 'Sierra Leone',
      ja: 'シエラレオネ',
      it: 'Sierra Leone',
      'zh-cn': '塞拉利昂',
      tr: 'Sierra Leone',
      ru: 'Сьерра-Леоне',
      uk: 'Сьєрра-Леоне',
      pl: 'Sierra Leone'
    },
    emoji: '🇸🇱'
  },
  {
    name: 'Singapore',
    code: 'SG',
    translations: {
      kr: '싱가포르',
      'pt-BR': 'Singapura',
      pt: 'Singapura',
      nl: 'Singapore',
      hr: 'Singapur',
      fa: 'سنگاپور',
      de: 'Singapur',
      es: 'Singapur',
      fr: 'Singapour',
      ja: 'シンガポール',
      it: 'Singapore',
      'zh-cn': '新加坡',
      tr: 'Singapur',
      ru: 'Сингапур',
      uk: 'Сінгапур',
      pl: 'Singapur'
    },
    emoji: '🇸🇬'
  },
  {
    name: 'Sint Maarten (Dutch part)',
    code: 'SX',
    translations: {
      kr: '신트마르턴',
      'pt-BR': 'Sint Maarten',
      pt: 'São Martinho',
      nl: 'Sint Maarten',
      fa: 'سینت مارتن',
      de: 'Sint Maarten (niederl. Teil)',
      fr: 'Saint Martin (partie néerlandaise)',
      it: 'Saint Martin (parte olandese)',
      'zh-cn': '圣马丁岛（荷兰部分）',
      tr: 'Sint Maarten',
      ru: 'Синт-Мартен (голландская часть)',
      uk: 'Сінт-Мартен (голландська частина)',
      pl: 'Sint Maarten (część niderlandzka)'
    },
    emoji: '🇸🇽'
  },
  {
    name: 'Slovakia',
    code: 'SK',
    translations: {
      kr: '슬로바키아',
      'pt-BR': 'Eslováquia',
      pt: 'Eslováquia',
      nl: 'Slowakije',
      hr: 'Slovačka',
      fa: 'اسلواکی',
      de: 'Slowakei',
      es: 'República Eslovaca',
      fr: 'Slovaquie',
      ja: 'スロバキア',
      it: 'Slovacchia',
      'zh-cn': '斯洛伐克',
      tr: 'Slovakya',
      ru: 'Словакия',
      uk: 'Словаччина',
      pl: 'Słowacja'
    },
    emoji: '🇸🇰'
  },
  {
    name: 'Slovenia',
    code: 'SI',
    translations: {
      kr: '슬로베니아',
      'pt-BR': 'Eslovênia',
      pt: 'Eslovénia',
      nl: 'Slovenië',
      hr: 'Slovenija',
      fa: 'اسلوونی',
      de: 'Slowenien',
      es: 'Eslovenia',
      fr: 'Slovénie',
      ja: 'スロベニア',
      it: 'Slovenia',
      'zh-cn': '斯洛文尼亚',
      tr: 'Slovenya',
      ru: 'Словения',
      uk: 'Словенія',
      pl: 'Słowenia'
    },
    emoji: '🇸🇮'
  },
  {
    name: 'Solomon Islands',
    code: 'SB',
    translations: {
      kr: '솔로몬 제도',
      'pt-BR': 'Ilhas Salomão',
      pt: 'Ilhas Salomão',
      nl: 'Salomonseilanden',
      hr: 'Solomonski Otoci',
      fa: 'جزایر سلیمان',
      de: 'Salomonen',
      es: 'Islas Salomón',
      fr: 'Îles Salomon',
      ja: 'ソロモン諸島',
      it: 'Isole Salomone',
      'zh-cn': '所罗门群岛',
      tr: 'Solomon Adalari',
      ru: 'Соломоновы острова',
      uk: 'Соломонові острови',
      pl: 'Wyspy Salomona'
    },
    emoji: '🇸🇧'
  },
  {
    name: 'Somalia',
    code: 'SO',
    translations: {
      kr: '소말리아',
      'pt-BR': 'Somália',
      pt: 'Somália',
      nl: 'Somalië',
      hr: 'Somalija',
      fa: 'سومالی',
      de: 'Somalia',
      es: 'Somalia',
      fr: 'Somalie',
      ja: 'ソマリア',
      it: 'Somalia',
      'zh-cn': '索马里',
      tr: 'Somali',
      ru: 'Сомали',
      uk: 'Сомалі',
      pl: 'Somalia'
    },
    emoji: '🇸🇴'
  },
  {
    name: 'South Africa',
    code: 'ZA',
    translations: {
      kr: '남아프리카 공화국',
      'pt-BR': 'República Sul-Africana',
      pt: 'República Sul-Africana',
      nl: 'Zuid-Afrika',
      hr: 'Južnoafrička Republika',
      fa: 'آفریقای جنوبی',
      de: 'Republik Südafrika',
      es: 'República de Sudáfrica',
      fr: 'Afrique du Sud',
      ja: '南アフリカ',
      it: 'Sud Africa',
      'zh-cn': '南非',
      tr: 'Güney Afrika Cumhuriyeti',
      ru: 'Южная Африка',
      uk: 'Південна Африка',
      pl: 'Republika Południowej Afryki'
    },
    emoji: '🇿🇦'
  },
  {
    name: 'South Georgia',
    code: 'GS',
    translations: {
      kr: '사우스조지아',
      'pt-BR': 'Ilhas Geórgias do Sul e Sandwich do Sul',
      pt: 'Ilhas Geórgia do Sul e Sanduíche do Sul',
      nl: 'Zuid-Georgia en Zuidelijke Sandwicheilanden',
      hr: 'Južna Georgija i otočje Južni Sandwich',
      fa: 'جزایر جورجیای جنوبی و ساندویچ جنوبی',
      de: 'Südgeorgien und die Südlichen Sandwichinseln',
      es: 'Islas Georgias del Sur y Sandwich del Sur',
      fr: 'Géorgie du Sud-et-les Îles Sandwich du Sud',
      ja: 'サウスジョージア・サウスサンドウィッチ諸島',
      it: 'Georgia del Sud e Isole Sandwich Meridionali',
      'zh-cn': '南乔治亚',
      tr: 'Güney Georgia',
      ru: 'Южная Джорджия',
      uk: 'Південна Джорджія',
      pl: 'Południowa Georgia'
    },
    emoji: '🇬🇸'
  },
  {
    name: 'South Korea',
    code: 'KR',
    translations: {
      kr: '대한민국',
      'pt-BR': 'Coreia do Sul',
      pt: 'Coreia do Sul',
      nl: 'Zuid-Korea',
      hr: 'Južna Koreja',
      fa: 'کره شمالی',
      de: 'Südkorea',
      es: 'Corea del Sur',
      fr: 'Corée du Sud',
      ja: '大韓民国',
      it: 'Corea del Sud',
      'zh-cn': '韩国',
      tr: 'Güney Kore',
      ru: 'Южная Корея',
      uk: 'Південна Корея',
      pl: 'Korea Południowa'
    },
    emoji: '🇰🇷'
  },
  {
    name: 'South Sudan',
    code: 'SS',
    translations: {
      kr: '남수단',
      'pt-BR': 'Sudão do Sul',
      pt: 'Sudão do Sul',
      nl: 'Zuid-Soedan',
      hr: 'Južni Sudan',
      fa: 'سودان جنوبی',
      de: 'Südsudan',
      es: 'Sudán del Sur',
      fr: 'Soudan du Sud',
      ja: '南スーダン',
      it: 'Sudan del sud',
      'zh-cn': '南苏丹',
      tr: 'Güney Sudan',
      ru: 'Южный Судан',
      uk: 'Південний Судан',
      pl: 'Sudan Południowy'
    },
    emoji: '🇸🇸'
  },
  {
    name: 'Spain',
    code: 'ES',
    translations: {
      kr: '스페인',
      'pt-BR': 'Espanha',
      pt: 'Espanha',
      nl: 'Spanje',
      hr: 'Španjolska',
      fa: 'اسپانیا',
      de: 'Spanien',
      es: 'España',
      fr: 'Espagne',
      ja: 'スペイン',
      it: 'Spagna',
      'zh-cn': '西班牙',
      tr: 'İspanya',
      ru: 'Испания',
      uk: 'Іспанія',
      pl: 'Hiszpania'
    },
    emoji: '🇪🇸'
  },
  {
    name: 'Sri Lanka',
    code: 'LK',
    translations: {
      kr: '스리랑카',
      'pt-BR': 'Sri Lanka',
      pt: 'Sri Lanka',
      nl: 'Sri Lanka',
      hr: 'Šri Lanka',
      fa: 'سری‌لانکا',
      de: 'Sri Lanka',
      es: 'Sri Lanka',
      fr: 'Sri Lanka',
      ja: 'スリランカ',
      it: 'Sri Lanka',
      'zh-cn': '斯里兰卡',
      tr: 'Sri Lanka',
      ru: 'Шри-Ланка',
      uk: 'Шрі-Ланка',
      pl: 'Sri Lanka'
    },
    emoji: '🇱🇰'
  },
  {
    name: 'Sudan',
    code: 'SD',
    translations: {
      kr: '수단',
      'pt-BR': 'Sudão',
      pt: 'Sudão',
      nl: 'Soedan',
      hr: 'Sudan',
      fa: 'سودان',
      de: 'Sudan',
      es: 'Sudán',
      fr: 'Soudan',
      ja: 'スーダン',
      it: 'Sudan',
      'zh-cn': '苏丹',
      tr: 'Sudan',
      ru: 'Судан',
      uk: 'Судан',
      pl: 'Sudan'
    },
    emoji: '🇸🇩'
  },
  {
    name: 'Suriname',
    code: 'SR',
    translations: {
      kr: '수리남',
      'pt-BR': 'Suriname',
      pt: 'Suriname',
      nl: 'Suriname',
      hr: 'Surinam',
      fa: 'سورینام',
      de: 'Suriname',
      es: 'Surinam',
      fr: 'Surinam',
      ja: 'スリナム',
      it: 'Suriname',
      'zh-cn': '苏里南',
      tr: 'Surinam',
      ru: 'Суринам',
      uk: 'Суринам',
      pl: 'Surinam'
    },
    emoji: '🇸🇷'
  },
  {
    name: 'Svalbard and Jan Mayen Islands',
    code: 'SJ',
    translations: {
      kr: '스발바르 얀마옌 제도',
      'pt-BR': 'Svalbard',
      pt: 'Svalbard',
      nl: 'Svalbard en Jan Mayen',
      hr: 'Svalbard i Jan Mayen',
      fa: 'سوالبارد و یان ماین',
      de: 'Svalbard und Jan Mayen',
      es: 'Islas Svalbard y Jan Mayen',
      fr: 'Svalbard et Jan Mayen',
      ja: 'スヴァールバル諸島およびヤンマイエン島',
      it: 'Svalbard e Jan Mayen',
      'zh-cn': '斯瓦尔巴和扬马延群岛',
      tr: 'Svalbard Ve Jan Mayen',
      ru: 'Шпицберген и острова Ян-Майен',
      uk: 'Шпіцберген та острови Ян-Майєн',
      pl: 'Wyspy Svalbard i Jan Mayen'
    },
    emoji: '🇸🇯'
  },
  {
    name: 'Sweden',
    code: 'SE',
    translations: {
      kr: '스웨덴',
      'pt-BR': 'Suécia',
      pt: 'Suécia',
      nl: 'Zweden',
      hr: 'Švedska',
      fa: 'سوئد',
      de: 'Schweden',
      es: 'Suecia',
      fr: 'Suède',
      ja: 'スウェーデン',
      it: 'Svezia',
      'zh-cn': '瑞典',
      tr: 'İsveç',
      ru: 'Швеция',
      uk: 'Швеція',
      pl: 'Szwecja'
    },
    emoji: '🇸🇪'
  },
  {
    name: 'Switzerland',
    code: 'CH',
    translations: {
      kr: '스위스',
      'pt-BR': 'Suíça',
      pt: 'Suíça',
      nl: 'Zwitserland',
      hr: 'Švicarska',
      fa: 'سوئیس',
      de: 'Schweiz',
      es: 'Suiza',
      fr: 'Suisse',
      ja: 'スイス',
      it: 'Svizzera',
      'zh-cn': '瑞士',
      tr: 'İsviçre',
      ru: 'Швейцария',
      uk: 'Швейцарія',
      pl: 'Szwajcaria'
    },
    emoji: '🇨🇭'
  },
  {
    name: 'Syria',
    code: 'SY',
    translations: {
      kr: '시리아',
      'pt-BR': 'Síria',
      pt: 'Síria',
      nl: 'Syrië',
      hr: 'Sirija',
      fa: 'سوریه',
      de: 'Syrien',
      es: 'Siria',
      fr: 'Syrie',
      ja: 'シリア・アラブ共和国',
      it: 'Siria',
      'zh-cn': '叙利亚',
      tr: 'Suriye',
      ru: 'Сирия',
      uk: 'Сирія',
      pl: 'Syria'
    },
    emoji: '🇸🇾'
  },
  {
    name: 'Taiwan',
    code: 'TW',
    translations: {
      kr: '대만',
      'pt-BR': 'Taiwan',
      pt: 'Taiwan',
      nl: 'Taiwan',
      hr: 'Tajvan',
      fa: 'تایوان',
      de: 'Taiwan',
      es: 'Taiwán',
      fr: 'Taïwan',
      ja: '台湾（中華民国）',
      it: 'Taiwan',
      'zh-cn': '中国台湾',
      tr: 'Tayvan',
      ru: 'Тайвань',
      uk: 'Тайвань',
      pl: 'Tajwan'
    },
    emoji: '🇹🇼'
  },
  {
    name: 'Tajikistan',
    code: 'TJ',
    translations: {
      kr: '타지키스탄',
      'pt-BR': 'Tajiquistão',
      pt: 'Tajiquistão',
      nl: 'Tadzjikistan',
      hr: 'Tađikistan',
      fa: 'تاجیکستان',
      de: 'Tadschikistan',
      es: 'Tayikistán',
      fr: 'Tadjikistan',
      ja: 'タジキスタン',
      it: 'Tagikistan',
      'zh-cn': '塔吉克斯坦',
      tr: 'Tacikistan',
      ru: 'Таджикистан',
      uk: 'Таджикистан',
      pl: 'Tadżykistan'
    },
    emoji: '🇹🇯'
  },
  {
    name: 'Tanzania',
    code: 'TZ',
    translations: {
      kr: '탄자니아',
      'pt-BR': 'Tanzânia',
      pt: 'Tanzânia',
      nl: 'Tanzania',
      hr: 'Tanzanija',
      fa: 'تانزانیا',
      de: 'Tansania',
      es: 'Tanzania',
      fr: 'Tanzanie',
      ja: 'タンザニア',
      it: 'Tanzania',
      'zh-cn': '坦桑尼亚',
      tr: 'Tanzanya',
      ru: 'Танзания',
      uk: 'Танзанія',
      pl: 'Tanzania'
    },
    emoji: '🇹🇿'
  },
  {
    name: 'Thailand',
    code: 'TH',
    translations: {
      kr: '태국',
      'pt-BR': 'Tailândia',
      pt: 'Tailândia',
      nl: 'Thailand',
      hr: 'Tajland',
      fa: 'تایلند',
      de: 'Thailand',
      es: 'Tailandia',
      fr: 'Thaïlande',
      ja: 'タイ',
      it: 'Tailandia',
      'zh-cn': '泰国',
      tr: 'Tayland',
      ru: 'Таиланд',
      uk: 'Таїланд',
      pl: 'Tajlandia'
    },
    emoji: '🇹🇭'
  },
  {
    name: 'The Bahamas',
    code: 'BS',
    translations: {
      kr: '바하마',
      'pt-BR': 'Bahamas',
      pt: 'Baamas',
      nl: 'Bahama’s',
      hr: 'Bahami',
      fa: 'باهاما',
      de: 'Bahamas',
      es: 'Bahamas',
      fr: 'Bahamas',
      ja: 'バハマ',
      it: 'Bahamas',
      'zh-cn': '巴哈马',
      tr: 'Bahamalar',
      ru: 'Багамы',
      uk: 'Багамські острови',
      pl: 'Bahamy'
    },
    emoji: '🇧🇸'
  },
  {
    name: 'The Gambia ',
    code: 'GM',
    translations: {
      kr: '감비아',
      'pt-BR': 'Gâmbia',
      pt: 'Gâmbia',
      nl: 'Gambia',
      hr: 'Gambija',
      fa: 'گامبیا',
      de: 'Gambia',
      es: 'Gambia',
      fr: 'Gambie',
      ja: 'ガンビア',
      it: 'Gambia',
      'zh-cn': '冈比亚',
      tr: 'Gambiya',
      ru: 'Гамбия',
      uk: 'Гамбія The',
      pl: 'Gambia The'
    },
    emoji: '🇬🇲'
  },
  {
    name: 'Timor-Leste',
    code: 'TL',
    translations: {
      kr: '동티모르',
      'pt-BR': 'Timor Leste',
      pt: 'Timor Leste',
      nl: 'Oost-Timor',
      hr: 'Istočni Timor',
      fa: 'تیمور شرقی',
      de: 'Timor-Leste',
      es: 'Timor Oriental',
      fr: 'Timor oriental',
      ja: '東ティモール',
      it: 'Timor Est',
      'zh-cn': '东帝汶',
      tr: 'Doğu Timor',
      ru: 'Тимор-Лешти',
      uk: 'Тимор-Лешті',
      pl: 'Timor Wschodni'
    },
    emoji: '🇹🇱'
  },
  {
    name: 'Togo',
    code: 'TG',
    translations: {
      kr: '토고',
      'pt-BR': 'Togo',
      pt: 'Togo',
      nl: 'Togo',
      hr: 'Togo',
      fa: 'توگو',
      de: 'Togo',
      es: 'Togo',
      fr: 'Togo',
      ja: 'トーゴ',
      it: 'Togo',
      'zh-cn': '多哥',
      tr: 'Togo',
      ru: 'Того',
      uk: 'Того',
      pl: 'Togo'
    },
    emoji: '🇹🇬'
  },
  {
    name: 'Tokelau',
    code: 'TK',
    translations: {
      kr: '토켈라우',
      'pt-BR': 'Tokelau',
      pt: 'Toquelau',
      nl: 'Tokelau',
      hr: 'Tokelau',
      fa: 'توکلائو',
      de: 'Tokelau',
      es: 'Islas Tokelau',
      fr: 'Tokelau',
      ja: 'トケラウ',
      it: 'Isole Tokelau',
      'zh-cn': '托克劳',
      tr: 'Tokelau',
      ru: 'Токелау',
      uk: 'Токелау',
      pl: 'Tokelau'
    },
    emoji: '🇹🇰'
  },
  {
    name: 'Tonga',
    code: 'TO',
    translations: {
      kr: '통가',
      'pt-BR': 'Tonga',
      pt: 'Tonga',
      nl: 'Tonga',
      hr: 'Tonga',
      fa: 'تونگا',
      de: 'Tonga',
      es: 'Tonga',
      fr: 'Tonga',
      ja: 'トンガ',
      it: 'Tonga',
      'zh-cn': '汤加',
      tr: 'Tonga',
      ru: 'Тонга',
      uk: 'Тонга',
      pl: 'Tonga'
    },
    emoji: '🇹🇴'
  },
  {
    name: 'Trinidad and Tobago',
    code: 'TT',
    translations: {
      kr: '트리니다드 토바고',
      'pt-BR': 'Trinidad e Tobago',
      pt: 'Trindade e Tobago',
      nl: 'Trinidad en Tobago',
      hr: 'Trinidad i Tobago',
      fa: 'ترینیداد و توباگو',
      de: 'Trinidad und Tobago',
      es: 'Trinidad y Tobago',
      fr: 'Trinité et Tobago',
      ja: 'トリニダード・トバゴ',
      it: 'Trinidad e Tobago',
      'zh-cn': '特立尼达和多巴哥',
      tr: 'Trinidad Ve Tobago',
      ru: 'Тринидад и Тобаго',
      uk: 'Тринідад і Тобаго',
      pl: 'Trynidad i Tobago'
    },
    emoji: '🇹🇹'
  },
  {
    name: 'Tunisia',
    code: 'TN',
    translations: {
      kr: '튀니지',
      'pt-BR': 'Tunísia',
      pt: 'Tunísia',
      nl: 'Tunesië',
      hr: 'Tunis',
      fa: 'تونس',
      de: 'Tunesien',
      es: 'Túnez',
      fr: 'Tunisie',
      ja: 'チュニジア',
      it: 'Tunisia',
      'zh-cn': '突尼斯',
      tr: 'Tunus',
      ru: 'Тунис',
      uk: 'Туніс',
      pl: 'Tunezja'
    },
    emoji: '🇹🇳'
  },
  {
    name: 'Turkey',
    code: 'TR',
    translations: {
      kr: '터키',
      'pt-BR': 'Turquia',
      pt: 'Turquia',
      nl: 'Turkije',
      hr: 'Turska',
      fa: 'ترکیه',
      de: 'Türkei',
      es: 'Turquía',
      fr: 'Turquie',
      ja: 'トルコ',
      it: 'Turchia',
      'zh-cn': '土耳其',
      tr: 'Türkiye',
      ru: 'Турция',
      uk: 'Туреччина',
      pl: 'Turcja'
    },
    emoji: '🇹🇷'
  },
  {
    name: 'Turkmenistan',
    code: 'TM',
    translations: {
      kr: '투르크메니스탄',
      'pt-BR': 'Turcomenistão',
      pt: 'Turquemenistão',
      nl: 'Turkmenistan',
      hr: 'Turkmenistan',
      fa: 'ترکمنستان',
      de: 'Turkmenistan',
      es: 'Turkmenistán',
      fr: 'Turkménistan',
      ja: 'トルクメニスタン',
      it: 'Turkmenistan',
      'zh-cn': '土库曼斯坦',
      tr: 'Türkmenistan',
      ru: 'Туркменистан',
      uk: 'Туркменістан',
      pl: 'Turkmenistan'
    },
    emoji: '🇹🇲'
  },
  {
    name: 'Turks and Caicos Islands',
    code: 'TC',
    translations: {
      kr: '터크스 케이커스 제도',
      'pt-BR': 'Ilhas Turcas e Caicos',
      pt: 'Ilhas Turcas e Caicos',
      nl: 'Turks- en Caicoseilanden',
      hr: 'Otoci Turks i Caicos',
      fa: 'جزایر تورکس و کایکوس',
      de: 'Turks- und Caicosinseln',
      es: 'Islas Turks y Caicos',
      fr: 'Îles Turques-et-Caïques',
      ja: 'タークス・カイコス諸島',
      it: 'Isole Turks e Caicos',
      'zh-cn': '特克斯和凯科斯群岛',
      tr: 'Turks Ve Caicos Adalari',
      ru: 'Острова Теркс и Кайкос',
      uk: 'Острови Теркс і Кайкос',
      pl: 'Wyspy Turks i Caicos'
    },
    emoji: '🇹🇨'
  },
  {
    name: 'Tuvalu',
    code: 'TV',
    translations: {
      kr: '투발루',
      'pt-BR': 'Tuvalu',
      pt: 'Tuvalu',
      nl: 'Tuvalu',
      hr: 'Tuvalu',
      fa: 'تووالو',
      de: 'Tuvalu',
      es: 'Tuvalu',
      fr: 'Tuvalu',
      ja: 'ツバル',
      it: 'Tuvalu',
      'zh-cn': '图瓦卢',
      tr: 'Tuvalu',
      ru: 'Тувалу',
      uk: 'Тувалу',
      pl: 'Tuvalu'
    },
    emoji: '🇹🇻'
  },
  {
    name: 'Uganda',
    code: 'UG',
    translations: {
      kr: '우간다',
      'pt-BR': 'Uganda',
      pt: 'Uganda',
      nl: 'Oeganda',
      hr: 'Uganda',
      fa: 'اوگاندا',
      de: 'Uganda',
      es: 'Uganda',
      fr: 'Uganda',
      ja: 'ウガンダ',
      it: 'Uganda',
      'zh-cn': '乌干达',
      tr: 'Uganda',
      ru: 'Уганда',
      uk: 'Уганда',
      pl: 'Uganda'
    },
    emoji: '🇺🇬'
  },
  {
    name: 'Ukraine',
    code: 'UA',
    translations: {
      kr: '우크라이나',
      'pt-BR': 'Ucrânia',
      pt: 'Ucrânia',
      nl: 'Oekraïne',
      hr: 'Ukrajina',
      fa: 'وکراین',
      de: 'Ukraine',
      es: 'Ucrania',
      fr: 'Ukraine',
      ja: 'ウクライナ',
      it: 'Ucraina',
      'zh-cn': '乌克兰',
      tr: 'Ukrayna',
      ru: 'Украина',
      uk: 'Україна',
      pl: 'Ukraina'
    },
    emoji: '🇺🇦'
  },
  {
    name: 'United Arab Emirates',
    code: 'AE',
    translations: {
      kr: '아랍에미리트',
      'pt-BR': 'Emirados árabes Unidos',
      pt: 'Emirados árabes Unidos',
      nl: 'Verenigde Arabische Emiraten',
      hr: 'Ujedinjeni Arapski Emirati',
      fa: 'امارات متحده عربی',
      de: 'Vereinigte Arabische Emirate',
      es: 'Emiratos Árabes Unidos',
      fr: 'Émirats arabes unis',
      ja: 'アラブ首長国連邦',
      it: 'Emirati Arabi Uniti',
      'zh-cn': '阿拉伯联合酋长国',
      tr: 'Birleşik Arap Emirlikleri',
      ru: 'Объединенные Арабские Эмираты',
      uk: "Об'єднані Арабські Емірати",
      pl: 'Zjednoczone Emiraty Arabskie'
    },
    emoji: '🇦🇪'
  },
  {
    name: 'United Kingdom',
    code: 'GB',
    translations: {
      kr: '영국',
      'pt-BR': 'Reino Unido',
      pt: 'Reino Unido',
      nl: 'Verenigd Koninkrijk',
      hr: 'Ujedinjeno Kraljevstvo',
      fa: 'بریتانیای کبیر و ایرلند شمالی',
      de: 'Vereinigtes Königreich',
      es: 'Reino Unido',
      fr: 'Royaume-Uni',
      ja: 'イギリス',
      it: 'Regno Unito',
      'zh-cn': '英国',
      tr: 'Birleşik Krallik',
      ru: 'Великобритания',
      uk: 'Сполучене Королівство',
      pl: 'Wielka Brytania'
    },
    emoji: '🇬🇧'
  },
  {
    name: 'United States',
    code: 'US',
    translations: {
      kr: '미국',
      'pt-BR': 'Estados Unidos',
      pt: 'Estados Unidos',
      nl: 'Verenigde Staten',
      hr: 'Sjedinjene Američke Države',
      fa: 'ایالات متحده آمریکا',
      de: 'Vereinigte Staaten von Amerika',
      es: 'Estados Unidos',
      fr: 'États-Unis',
      ja: 'アメリカ合衆国',
      it: "Stati Uniti D'America",
      'zh-cn': '美国',
      tr: 'Amerika',
      ru: 'Соединенные Штаты',
      uk: 'Сполучені Штати',
      pl: 'Stany Zjednoczone'
    },
    emoji: '🇺🇸'
  },
  {
    name: 'United States Minor Outlying Islands',
    code: 'UM',
    translations: {
      kr: '미국령 군소 제도',
      'pt-BR': 'Ilhas Menores Distantes dos Estados Unidos',
      pt: 'Ilhas Menores Distantes dos Estados Unidos',
      nl: 'Kleine afgelegen eilanden van de Verenigde Staten',
      hr: 'Mali udaljeni otoci SAD-a',
      fa: 'جزایر کوچک حاشیه‌ای ایالات متحده آمریکا',
      de: 'Kleinere Inselbesitzungen der Vereinigten Staaten',
      es: 'Islas Ultramarinas Menores de Estados Unidos',
      fr: 'Îles mineures éloignées des États-Unis',
      ja: '合衆国領有小離島',
      it: "Isole minori esterne degli Stati Uniti d'America",
      'zh-cn': '美国本土外小岛屿',
      tr: 'Abd Küçük Harici Adalari',
      ru: 'Малые отдаленные острова Соединенных Штатов',
      uk: 'Малі віддалені острови Сполучених Штатів',
      pl: 'Mniejsze Wyspy Zewnętrzne Stanów Zjednoczonych'
    },
    emoji: '🇺🇲'
  },
  {
    name: 'Uruguay',
    code: 'UY',
    translations: {
      kr: '우루과이',
      'pt-BR': 'Uruguai',
      pt: 'Uruguai',
      nl: 'Uruguay',
      hr: 'Urugvaj',
      fa: 'اروگوئه',
      de: 'Uruguay',
      es: 'Uruguay',
      fr: 'Uruguay',
      ja: 'ウルグアイ',
      it: 'Uruguay',
      'zh-cn': '乌拉圭',
      tr: 'Uruguay',
      ru: 'Уругвай',
      uk: 'Уругвай',
      pl: 'Urugwaj'
    },
    emoji: '🇺🇾'
  },
  {
    name: 'Uzbekistan',
    code: 'UZ',
    translations: {
      kr: '우즈베키스탄',
      'pt-BR': 'Uzbequistão',
      pt: 'Usbequistão',
      nl: 'Oezbekistan',
      hr: 'Uzbekistan',
      fa: 'ازبکستان',
      de: 'Usbekistan',
      es: 'Uzbekistán',
      fr: 'Ouzbékistan',
      ja: 'ウズベキスタン',
      it: 'Uzbekistan',
      'zh-cn': '乌兹别克斯坦',
      tr: 'Özbekistan',
      ru: 'Узбекистан',
      uk: 'Узбекистан',
      pl: 'Uzbekistan'
    },
    emoji: '🇺🇿'
  },
  {
    name: 'Vanuatu',
    code: 'VU',
    translations: {
      kr: '바누아투',
      'pt-BR': 'Vanuatu',
      pt: 'Vanuatu',
      nl: 'Vanuatu',
      hr: 'Vanuatu',
      fa: 'وانواتو',
      de: 'Vanuatu',
      es: 'Vanuatu',
      fr: 'Vanuatu',
      ja: 'バヌアツ',
      it: 'Vanuatu',
      'zh-cn': '瓦努阿图',
      tr: 'Vanuatu',
      ru: 'Вануату',
      uk: 'Вануату',
      pl: 'Vanuatu'
    },
    emoji: '🇻🇺'
  },
  {
    name: 'Vatican City State (Holy See)',
    code: 'VA',
    translations: {
      kr: '바티칸 시국',
      'pt-BR': 'Vaticano',
      pt: 'Vaticano',
      nl: 'Heilige Stoel',
      hr: 'Sveta Stolica',
      fa: 'سریر مقدس',
      de: 'Heiliger Stuhl',
      es: 'Santa Sede',
      fr: 'voir Saint',
      ja: '聖座',
      it: 'Santa Sede',
      'zh-cn': '梵蒂冈',
      tr: 'Vatikan',
      ru: 'Город-государство Ватикан (Святой Престол)',
      uk: 'Держава-місто Ватикан (Святий Престол)',
      pl: 'Państwo Watykańskie (Stolica Apostolska)'
    },
    emoji: '🇻🇦'
  },
  {
    name: 'Venezuela',
    code: 'VE',
    translations: {
      kr: '베네수엘라',
      'pt-BR': 'Venezuela',
      pt: 'Venezuela',
      nl: 'Venezuela',
      hr: 'Venezuela',
      fa: 'ونزوئلا',
      de: 'Venezuela',
      es: 'Venezuela',
      fr: 'Venezuela',
      ja: 'ベネズエラ・ボリバル共和国',
      it: 'Venezuela',
      'zh-cn': '委内瑞拉',
      tr: 'Venezuela',
      ru: 'Венесуэла',
      uk: 'Венесуела',
      pl: 'Wenezuela'
    },
    emoji: '🇻🇪'
  },
  {
    name: 'Vietnam',
    code: 'VN',
    translations: {
      kr: '베트남',
      'pt-BR': 'Vietnã',
      pt: 'Vietname',
      nl: 'Vietnam',
      hr: 'Vijetnam',
      fa: 'ویتنام',
      de: 'Vietnam',
      es: 'Vietnam',
      fr: 'Viêt Nam',
      ja: 'ベトナム',
      it: 'Vietnam',
      'zh-cn': '越南',
      tr: 'Vietnam',
      ru: 'Вьетнам',
      uk: "В'єтнам",
      pl: 'Wietnam'
    },
    emoji: '🇻🇳'
  },
  {
    name: 'Virgin Islands (British)',
    code: 'VG',
    translations: {
      kr: '영국령 버진아일랜드',
      'pt-BR': 'Ilhas Virgens Britânicas',
      pt: 'Ilhas Virgens Britânicas',
      nl: 'Britse Maagdeneilanden',
      hr: 'Britanski Djevičanski Otoci',
      fa: 'جزایر ویرجین بریتانیا',
      de: 'Britische Jungferninseln',
      es: 'Islas Vírgenes del Reino Unido',
      fr: 'Îles Vierges britanniques',
      ja: 'イギリス領ヴァージン諸島',
      it: 'Isole Vergini Britanniche',
      'zh-cn': '圣文森特和格林纳丁斯',
      tr: 'Britanya Virjin Adalari',
      ru: 'Виргинские острова (Британские)',
      uk: 'Віргінські острови (Британські)',
      pl: 'Wyspy Dziewicze (Brytyjskie)'
    },
    emoji: '🇻🇬'
  },
  {
    name: 'Virgin Islands (US)',
    code: 'VI',
    translations: {
      kr: '미국령 버진아일랜드',
      'pt-BR': 'Ilhas Virgens Americanas',
      pt: 'Ilhas Virgens Americanas',
      nl: 'Verenigde Staten Maagdeneilanden',
      fa: 'جزایر ویرجین آمریکا',
      de: 'Amerikanische Jungferninseln',
      es: 'Islas Vírgenes de los Estados Unidos',
      fr: 'Îles Vierges des États-Unis',
      ja: 'アメリカ領ヴァージン諸島',
      it: 'Isole Vergini americane',
      'zh-cn': '维尔京群岛（美国）',
      tr: 'Abd Virjin Adalari',
      ru: 'Виргинские острова (США)',
      uk: 'Віргінські острови (США)',
      pl: 'Wyspy Dziewicze (USA)'
    },
    emoji: '🇻🇮'
  },
  {
    name: 'Wallis and Futuna Islands',
    code: 'WF',
    translations: {
      kr: '왈리스 푸투나',
      'pt-BR': 'Wallis e Futuna',
      pt: 'Wallis e Futuna',
      nl: 'Wallis en Futuna',
      hr: 'Wallis i Fortuna',
      fa: 'والیس و فوتونا',
      de: 'Wallis und Futuna',
      es: 'Wallis y Futuna',
      fr: 'Wallis-et-Futuna',
      ja: 'ウォリス・フツナ',
      it: 'Wallis e Futuna',
      'zh-cn': '瓦利斯群岛和富图纳群岛',
      tr: 'Wallis Ve Futuna',
      ru: 'Острова Уоллис и Футуна',
      uk: 'Острови Уолліс і Футуна',
      pl: 'Wyspy Wallis i Futuna'
    },
    emoji: '🇼🇫'
  },
  {
    name: 'Western Sahara',
    code: 'EH',
    translations: {
      kr: '서사하라',
      'pt-BR': 'Saara Ocidental',
      pt: 'Saara Ocidental',
      nl: 'Westelijke Sahara',
      hr: 'Zapadna Sahara',
      fa: 'جمهوری دموکراتیک عربی صحرا',
      de: 'Westsahara',
      es: 'Sahara Occidental',
      fr: 'Sahara Occidental',
      ja: '西サハラ',
      it: 'Sahara Occidentale',
      'zh-cn': '西撒哈拉',
      tr: 'Bati Sahra',
      ru: 'Западная Сахара',
      uk: 'Західна Сахара',
      pl: 'Sahara Zachodnia'
    },
    emoji: '🇪🇭'
  },
  {
    name: 'Yemen',
    code: 'YE',
    translations: {
      kr: '예멘',
      'pt-BR': 'Iêmen',
      pt: 'Iémen',
      nl: 'Jemen',
      hr: 'Jemen',
      fa: 'یمن',
      de: 'Jemen',
      es: 'Yemen',
      fr: 'Yémen',
      ja: 'イエメン',
      it: 'Yemen',
      'zh-cn': '也门',
      tr: 'Yemen',
      ru: 'Йемен',
      uk: 'Ємен',
      pl: 'Jemen'
    },
    emoji: '🇾🇪'
  },
  {
    name: 'Zambia',
    code: 'ZM',
    translations: {
      kr: '잠비아',
      'pt-BR': 'Zâmbia',
      pt: 'Zâmbia',
      nl: 'Zambia',
      hr: 'Zambija',
      fa: 'زامبیا',
      de: 'Sambia',
      es: 'Zambia',
      fr: 'Zambie',
      ja: 'ザンビア',
      it: 'Zambia',
      'zh-cn': '赞比亚',
      tr: 'Zambiya',
      ru: 'Замбия',
      uk: 'Замбія',
      pl: 'Zambia'
    },
    emoji: '🇿🇲'
  },
  {
    name: 'Zimbabwe',
    code: 'ZW',
    translations: {
      kr: '짐바브웨',
      'pt-BR': 'Zimbabwe',
      pt: 'Zimbabué',
      nl: 'Zimbabwe',
      hr: 'Zimbabve',
      fa: 'زیمباوه',
      de: 'Simbabwe',
      es: 'Zimbabue',
      fr: 'Zimbabwe',
      ja: 'ジンバブエ',
      it: 'Zimbabwe',
      'zh-cn': '津巴布韦',
      tr: 'Zimbabve',
      ru: 'Зимбабве',
      uk: 'Зімбабве',
      pl: 'Zimbabwe'
    },
    emoji: '🇿🇼'
  }
]
